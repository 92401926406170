import { useDispatch } from 'react-redux';
import { useAccount, useConnect, useDisconnect, useEnsName, useNetwork } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { createContext, useEffect } from 'react';
import { removeConnectedWallets, setChain, setConnectedWallets } from '../Redux/walletReducer';
import { chainIds } from '../Api/blockpit';
import { validateAddress } from '../Utils/addressValidator';

// export const WalletContext = createContext<any>({});

export const useConnectWallet = () => {
  const dispatch = useDispatch();

  const { connect: wagmiConnect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect: wagmiDisconnect } = useDisconnect();
  const { address, isConnected, isConnecting, isDisconnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { chain } = useNetwork();

  const connect = async () => {
    if (isConnecting) return;
    try {
      if (!isConnected) {
        wagmiConnect();
      }
    } catch (e) {
      console.log('Error connecting', e);
    }
  };

  const disconnect = async () => {
    if (isDisconnected) return;
    try {
      console.log('disconnect');
      wagmiDisconnect();
      dispatch(removeConnectedWallets(true));
    } catch (e) {
      console.log('Error connecting', e);
    }
  };

  useEffect(() => {
    if (address) {
      console.log('address', address);
      const validFor = chainIds
        .map((chain) => ({ chain, valid: validateAddress(address, chain) }))
        .filter((c) => c.valid)
        .map((c) => c.chain);
      dispatch(setConnectedWallets({ address, validFor }));
    } else {
      dispatch(removeConnectedWallets);
    }
  }, [address]);

  useEffect(() => {
    dispatch(setChain(chain?.id || -1));
  }, [chain?.id]);

  // useEffect(() => {
  //   connect();
  // }, []);

  return { connect, disconnect, isConnected, address, ensName };
};
