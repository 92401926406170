import { ChainID } from '../Api/blockpit';
import { ReactElement } from 'react';
export interface StoredWallet {
  address: string;
  validFor: ChainID[];
}
export interface BreakdownItem {
  label: string;
  icon: string | null;
  value: number;
}

export type NFTCollectionProps = {
  name: string;
  contract: string;
  coverImg: string;
  slug?: string;
  owned?: string;
};

export interface NFTCollectionResponse {
  collection: {
    id: string;
    slug: string;
    name: string;
    image: string;
    banner: string;
    discordUrl: string;
    externalUrl: string;
    twitterUsername: string;
    description: string;
    sampleImages: string[];
    tokenCount: string;
    primaryContract: string;
    tokenSetId: string;
    floorAskPrice: number;
    rank: { '1day': number; '7day': number; '30day': number; allTime: number };
    volume: { '1day': number; '7day': number; '30day': number; allTime: number };
    volumeChange: { '1day': number; '7day': number; '30day': number };
    floorSale: { '1day': number; '7day': number; '30day': number };
  };
  ownership: {
    tokenCount: string;
    onSaleCount: string;
  };
}

export interface NFTAssetResponse {
  token: {
    contract: string;
    tokenId: string;
    name: string;
    image: string;
    collection: {
      id: string;
      name: string;
      imageUrl: string;
      floorAskPrice: number;
    };
  };
  ownership: {
    tokenCount: string;
    onSaleCount: string;
    floorAsk: {
      id: string;
      price: NFTPrice;
      maker: string;
      validFrom: string;
      validUntil: string;
      source: any;
    };
    acquiredAt: string;
  };
}
export interface NFTPrice {
  currency: {
    contract: string;
    name: string;
    symbol: string;
    decimals: number;
  };
  amount: {
    raw: string;
    decimal: number;
    usd: number;
    native: number;
  };
  netAmount: {
    raw: string;
    decimal: number;
    usd: number;
    native: number;
  };
}

export interface NFTAsset {
  slug: string;
  id: number;
  contract_address: any;
  token_id: string;
  num_sales: number;
  background_color: string;
  image_url: string;
  image_preview_url: string;
  image_thumbnail_url: string;
  image_original_url: string;
  animation_url: string;
  animation_original_url: string;
  name: string;
  description: string;
  external_link: string;
  permalink: string;
  decimals: number;
  token_metadata: string;
  traits: any[];
  noCache?: boolean;
  created_date: any;
  website: string;
  discord: string;
  twitter: string;
  collection: any;
  owner: any;
  onSale: any;
  sell_orders: any;
  asset_contract?: any;
  rank?: number;
  score?: number;
  mapped_traits?: string;
  is_nsfw?: boolean;
  seaport_sell_orders?: any;
  creator?: any;
  last_sale?: any;
  top_bid?: any;
  listing_date?: any;
  is_presale?: boolean;
  transfer_fee_payment_token?: any;
  transfer_fee?: any;
  rarity_data?: any;
}

export type TransactionMember = {
  address: string;
  quantity: number;
  identifier: {
    name: string;
    uid: string;
  };
  decimals: number;
  currency: string;
  currency_name: string;
  currency_symbol: string;
  weight: number;
  log_index: number;
};
export const transactionTypes = [
  'deposit',
  'withdrawal',
  'intern',
  'trade',
  'fee',
  'airdrop',
  'lending_reward',
  'staking_reward',
];
type TransactionType = typeof transactionTypes[number];
export type Transaction = {
  transaction_type: TransactionType;
  from: TransactionMember[];
  to: TransactionMember[];
  platform: {
    contract: string;
    kyt_label: string;
    kyt_category: string;
    kyt_contract_id: number;
  };
  kyt_pattern_label: string;
  kyt_txdb_id: number;
  blocknumber: number;
  transactionhash: string;
  txid: string;
  failed: boolean;
  nonce: number;
  quantity: string;
  quantity_currency: string;
  fee_currency: string;
  wallet: string;
  // fee: {
  //   quantity: number;
  //   // quantity: {
  //   //   _sign: number;
  //   //   _bits: number[];
  //   //   IsPowerOfTwo: boolean;
  //   //   IsZero: boolean;
  //   //   IsOne: boolean;
  //   //   IsEven: boolean;
  //   //   Sign: 0;
  //   // };
  //   decimals: number;
  //   currency: string;
  //   currency_name: string;
  //   currency_symbol: string;
  // };
  fee: string | TransactionMember;
  timestamp: string;
};
// export type Transaction = {
//   status: boolean;
//   message: string;
//   height: number;
//   version: string;
//   result: [

//   ];
// };

export type TransactionDisplay = {
  transaction: {
    date: string;
    day: number;
    timestamp: number;
    hash: string;
    chain: ChainID;
    wallets: string[];
    assets: string[];
    amounts: (string | number)[];
    type: TransactionType;
    link: string;
  };
  details: string | ReactElement;
  fee: {
    amount: number;
    token: string;
    value: number;
  };
  data: {
    token: string | string[];
    quantity: string | number | number[] | string[];
    fee: {
      symbol: string;
      quantity: number;
    } | null;
  };
  // raw: Transaction;
  // date: number;
  // wallets: string;
  // blockchain: ChainID;
  // assets: string;
  // amount: number;
  // type: TransactionType;
};

export type TokenDetails = {
  decimals: number;
  name: string;
  symbol: string;
  contractaddress: string;
  tokenType: string;
  tokenId: string;
  tokenURI: unknown;
  structure: unknown;
  details: unknown;
  icon?: string;
};

export type TokenData = {
  chain: ChainID;
  address: string;
  token: TokenDetails;
  icon?: string;
};

export type ParsedBalance = {
  chain: ChainID;
  wallet: string;
  token: {
    asset_id: number;
    symbol: string;
    name: string;
    address: string;
    identifier: string | null;
    decimals: number;
    icon?: string;
  };
  balance: string | number;
  priceId?: string;
  prices?: { USD: number; EUR: number };
  value?: number;
};

// export type ParsedBalance = {
//   chain: ChainID;
//   wallet: string;
//   token: TokenDetails | undefined;
//   balance: string | number;
//   priceId?: string;
//   price?: number;
//   value?: number;
// };

export type PortfolioSummary = {
  netWorth: number;
  available: number;
  claimable: number;
  delegated: number;
  // netWorth: number;
  // claimable: number;
  // assets: number;
  // rewards: number;
};
