import clsx from 'clsx';
import { groupBy, sumBy, orderBy, isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { BreakdownItem, ParsedBalance, PortfolioSummary } from '../../types';
import { CubeTransparentIcon, LinkIcon } from '@heroicons/react/24/solid';
import { getWalletBalances } from '../../Api/dashboard';
import { selectAllWallets } from '../../Redux/walletReducer';
import { useAppSelector } from '../../Redux';
import { chains } from '../../constants';
import { Chain } from '../../Api/blockpit';
import TokensTable from './Components/Tables/Tokens1';
import BreakdownCard from './Components/BreakdownCard';
import Summary from './Components/Summary';
import Skeleton from '../../UI/skeleton/no-wallet';

export type TokenSplit = ParsedBalance[] & { percent: number };
export type ChainSplit = Chain & { percent: number };

const Dashboard = () => {
  const [tokens, setTokens] = useState<ParsedBalance[] | null>();
  const [commission, setComission] = useState<ParsedBalance[] | null>();
  const [delegated, setDelegated] = useState<ParsedBalance[] | null>();
  const [stakingRewards, setStakingRewards] = useState<ParsedBalance[] | null>();
  const [summary, setSummary] = useState<PortfolioSummary | null>(null);
  const [tokenSplit, setTokenSplit] = useState<BreakdownItem[] | null>(null);
  const [chainSplit, setChainSplit] = useState<BreakdownItem[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const wallets = useAppSelector(selectAllWallets, isEqual);

  const doCalculateSummary = useCallback(
    (
      tokensData: ParsedBalance[],
      commission: ParsedBalance[],
      stakingReward: ParsedBalance[],
      delegatedDt: ParsedBalance[]
    ) => {
      // const netWorth = sumBy(tokensData, (t) => Number(t?.value));
      const available = sumBy(tokensData, (t) => Number(t?.value));
      const commissionSum = sumBy(commission, (t) => Number(t?.value));
      const stakingSum = sumBy(stakingReward, (t) => Number(t?.value));
      const delegated = sumBy(delegatedDt, (t) => Number(t?.value));
      const netWorth = available + commissionSum + stakingSum + delegated;
      const claimable = commissionSum + stakingSum;
      // const assets = netWorth;
      // const rewards = sumBy(stakingReward, (t) => Number(t?.value));
      // const claimable = sumBy(commission, (t) => Number(t?.value));
      setSummary({ netWorth, available, claimable, delegated });

      const groupByChain = groupBy(tokensData, 'chain');
      const byChainData = Object.keys(groupByChain).map((chain) => {
        const data = chains.find((c) => c.id === chain);
        const chainBalance = sumBy(groupByChain[chain], (t) => Number(t?.value));
        const percent = (chainBalance / netWorth) * 100;
        return {
          label: data?.symbol || 'Anon',
          icon: data?.icon || null,
          value: percent > 0 && !isNaN(percent) ? percent : 0,
        };
      });
      const topChains = orderBy(byChainData, 'value', 'desc').slice(0, 4);
      // TODO, dont show other chains always, only if needed, in the case of Vladimir's example it was showing ATOM 5% and others 95% even though there were no more "others", just ATOM, that's becuase of this 100-value we're doing here, apply same logic to token allocation
      const otherChains = { label: 'Others', icon: null, value: 100 - sumBy(topChains, 'value') };
      if (otherChains.value > 0.01) topChains.push(otherChains);
      setChainSplit(topChains);

      const groupByToken = groupBy(tokensData, (t) => t?.token?.symbol);
      const mergedTokens = Object.entries(groupByToken).map(([symbol, tokens]) => {
        const value = sumBy(tokens, (t) => Number(t?.value));
        const balance = sumBy(tokens, (t) => Number(t?.balance));
        return { ...tokens[0], value, balance };
      });
      const byTokenData = mergedTokens.map((t) => {
        const chain = chains.find((c) => c.id === t.chain);
        const percent = (Number(t?.value) / netWorth) * 100;
        const tk = t.token;
        return {
          label: tk?.symbol || 'Anon',
          icon: tk.symbol === chain?.symbol ? chain.icon : tk?.icon || null,
          value: percent > 0 && !isNaN(percent) ? percent : 0,
        };
      });
      const topTokens = orderBy(byTokenData, 'value', 'desc').slice(0, 4);
      const otherTokens = { label: 'Others', icon: null, value: 100 - sumBy(topTokens, 'value') };
      const tableTokens = orderBy(mergedTokens, 'value', 'desc');
      if (otherTokens.value > 0.01) topTokens.push(otherTokens);
      setTokenSplit(topTokens);
      setTokens(tableTokens);
    },
    [tokens]
  );

  const doGetDashboard = async () => {
    setLoading(true);
    setTokens(null);
    setComission(null);
    setDelegated(null);
    setStakingRewards(null);
    setError(null);
    try {
      const data = await getWalletBalances(wallets);
      if (data) {
        const { balances, delegated, commission, staking_reward } = data;
        if (balances && balances.length) {
          setTokens(balances);
          doCalculateSummary(balances, commission, staking_reward, delegated);
        }
        if (delegated && delegated.length) {
          setDelegated(delegated);
        }
        if (commission && commission.length) {
          setComission(commission);
        }
        if (staking_reward && staking_reward.length) {
          setStakingRewards(staking_reward);
        }
      }
    } catch (error) {
      setError('Unable to load dashboard');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await doGetDashboard();
    })();
  }, [wallets]);

  if (!wallets.length) {
    return <Skeleton message='Connect a wallet first' />;
  }
  return (
    <>
      <section aria-labelledby='portfolio-summary'>
        <div className='rounded-lg bg-light-dark overflow-hidden shadow min-h-[205px]'>
          <Summary summary={summary} loading={loading} onUpdate={() => doGetDashboard()} />
        </div>
      </section>

      <section aria-labelledby='portfolio-breakdowns'>
        <div className='rounded-lg bg-gray-800 overflow-hidden shadow divide-y divide-gray-800 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px'>
          <div
            className={clsx(
              'rounded-t-lg sm:rounded-tr-none',
              'group relative bg-light-dark p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500 min-h-[15rem] sm:min-h-fit sm:h-80'
            )}
          >
            <BreakdownCard
              title='Token Allocation'
              subtitle='The distribution of your net worth across tokens.'
              icon={<CubeTransparentIcon className='h-4 w-4' />}
              items={tokenSplit}
              loading={loading}
            />
          </div>
          <div
            className={clsx(
              'sm:rounded-tr-lg',
              'group relative bg-light-dark p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500 min-h-[15rem] sm:min-h-fit sm:h-80'
            )}
          >
            <BreakdownCard
              title='Chain Allocation'
              subtitle='The distribution of your net worth across chains.'
              icon={<LinkIcon className='h-4 w-4' />}
              items={chainSplit}
              loading={loading}
            />
          </div>
        </div>
      </section>

      <section aria-labelledby=''>
        <div className='h-full'>
          {tokens && tokens.length && <TokensTable tableData={tokens} title='Available' />}
        </div>
      </section>

      {delegated && (
        <section aria-labelledby=''>
          <div className='h-full'>
            <TokensTable tableData={delegated} title='Delegated' />
          </div>
        </section>
      )}

      {commission && (
        <section aria-labelledby=''>
          <div className='h-full'>
            <TokensTable tableData={commission} title='Claimable' />
          </div>
        </section>
      )}

      {stakingRewards && (
        <section aria-labelledby=''>
          <div className='h-full'>
            <TokensTable tableData={stakingRewards} title='Staking Rewards' />
          </div>
        </section>
      )}

      {/* <section aria-labelledby=''>
        <div className='h-full'>
          <OsmosisTable />
        </div>
      </section> */}

      <section aria-labelledby=''>{/* <app-rewards></app-rewards> */}</section>
    </>
  );
};

export default Dashboard;
