import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { dismissModal, setModal, updateModal } from '../Redux/appReducer';
import { Modal } from '../UI/modal';
// import { updateModal, dismissModal, setModal } from '../../Redux/appReducer';
// import { Modal } from '../../UI/modal';

export const useModal = (update?: Partial<Modal>) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (update) {
      dispatch(updateModal(update));
    }
  }, []);

  return {
    hide: () => dispatch(dismissModal()),
    show: (modal: Modal) => dispatch(setModal(modal)),
    showBack: (onBack: () => void) => dispatch(updateModal({ onBack })),
    hideBack: () => dispatch(updateModal({ onBack: undefined })),
    update: (modal: Partial<Modal>) => dispatch(updateModal(modal)),
  };
};
