import clsx from 'clsx';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { StoredWallet } from '../../types';
import { truncateMiddle } from '../../Utils/truncateMiddle';

const SelectWallet = ({
  wallets,
  selectedWallet,
  setSelectedWallet,
}: {
  wallets: StoredWallet[];
  selectedWallet: StoredWallet;
  setSelectedWallet: React.Dispatch<React.SetStateAction<StoredWallet>>;
}) => {
  return (
    <div className='relative w-full md:w-auto z-50'>
      <Listbox value={selectedWallet} onChange={setSelectedWallet}>
        <Listbox.Button className='flex h-11 w-full items-center justify-between rounded-lg px-4 text-sm bg-dark text-white md:w-36 lg:w-56 xl:w-56'>
          {truncateMiddle(selectedWallet.address, 20, '...')}
          <ChevronDownIcon className='w-4 h-4' />
        </Listbox.Button>
        <Transition
          enter='ease-out duration-200'
          enterFrom='opacity-0 translate-y-2'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 -translate-y-0'
          leaveTo='opacity-0 translate-y-2'
        >
          <Listbox.Options className='absolute left-0 z-10 mt-2 w-full origin-top-right rounded-lg p-3 shadow-large bg-dark'>
            {wallets.map((wallet, idx) => (
              <Listbox.Option key={idx} value={wallet}>
                {({ selected }) => (
                  <div
                    className={clsx(
                      `block cursor-pointer rounded-lg px-3 py-2 text-sm font-medium transition text-white`,
                      selected ? 'my-1 bg-gray-800' : 'hover:bg-gray-700'
                    )}
                  >
                    {truncateMiddle(wallet.address, 20, '...')}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default SelectWallet;
