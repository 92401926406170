// Adapted from https://github.com/marksuurland/crypto-address-validator-ts

import { getAll, getByNameOrSymbol } from './currencies';
import { Options } from './types';
import { ChainID } from '../../Api/blockpit';

export const validate = (address: string, currencyNameOrSymbol: string, opts: Options) => {
  const currency = getByNameOrSymbol(currencyNameOrSymbol);

  if (currency && currency.validator) {
    return currency.validator(address, currency, opts);
  }

  throw new Error('Missing validator for currency: ' + currencyNameOrSymbol);
};

export const getCurrencies = () => {
  return getAll();
};

export const findCurrency = (symbol: string) => {
  return getByNameOrSymbol(symbol) || null;
};

export const validateAddress = (address: string, chain: ChainID) => {
  try {
    return !!validate(address, chain, {
      networkType: 'prod',
      chainType: 'mainnet',
    });
  } catch (error) {
    return false;
  }
};
