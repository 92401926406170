import './App.css';
import Header from './layout/Header';
import Dashboard from './Pages/Dashboard';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import DrawerContainer from './UI/drawer';

function App() {
  return (
    <div className='min-h-full font-body'>
      <Helmet>
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link
          href='https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap'
          rel='stylesheet'
        />
      </Helmet>
      <Header />
      <main className='-mt-24 pb-8 overflow-x-hidden'>
        <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-5xl lg:px-8'>
          <div className='grid'>
            <div className='grid grid-cols-1 gap-4 lg:col-span-2'>
              <Outlet />
              <DrawerContainer />
            </div>
          </div>
        </div>
      </main>
      <footer>
        <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-5xl'>
          <div className='border-t border-gray-800 py-8 text-sm text-gray-500 text-center sm:text-left'>
            <span className='block sm:inline'>
              &copy; {new Date().getFullYear()} Powered by Blockpit AG{' '}
            </span>{' '}
            {/* <span className='block sm:inline'>All rights reserved.</span> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
