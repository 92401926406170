import type { BreakdownItem as BreakdownItemType } from '../../../../types';
import { ReactElement } from 'react';
import BreakdownChart from './BreakdownChart';
import BreakdownItem from './BreakdownItem';
import Loader from '../../../../UI/loader';

const BreakdownCard = ({
  title,
  subtitle,
  items,
  icon,
  loading,
}: {
  title: string;
  subtitle: string;
  items: BreakdownItemType[] | null;
  icon?: string | ReactElement;
  loading?: boolean;
}) => {
  if (!items || loading) return <Loader variant='blink' size='small' className='mt-4 ml-0.5' />;

  return (
    <>
      <div>
        <h3 className='text-lg font-medium'>
          <a href='#' className='focus:outline-none flex gap-2'>
            <div>
              <span className='relative flex shrink-0 items-center justify-center rounded-lg border shadow-main border-gray-700 bg-light-dark text-white h-7 w-7'>
                {icon}
              </span>
            </div>
            <span className='text-white'>{title}</span>
          </a>
        </h3>
        <p className='mt-2 text-sm text-gray-400'>{subtitle}</p>
        <div className='mt-7 flex flex-row items-center justify-between gap-2 sm:gap-0'>
          <div className='flex sm:pt-3 shrink max-w-[200px]'>
            <BreakdownChart items={items} />
          </div>
          <div className='pr-3'>
            <div className='flex flex-col gap-3 py-2 w-44'>
              {items.map((item) => (
                <BreakdownItem key={item.label} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <span
        className='pointer-events-none absolute top-7 right-7 text-gray-400 group-hover:text-gray-500'
        aria-hidden='true'
      >
        {/* <svg
          className='h-5 w-5'
          xmlns='http://www.w3.org/2000/svg'
          fill='currentColor'
          viewBox='0 0 24 24'
        >
          <path d='M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z' />
        </svg> */}
      </span>
    </>
  );
};

export default BreakdownCard;
