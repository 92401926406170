import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '.';
import { Drawer } from '../UI/drawer';
import { Modal } from '../UI/modal';
import { PartiallyOptional, ToastMessage } from '../UI/toast';

type AppState = {
  modal: Modal | null;
  drawer: Drawer | null;
  toastMessages: ToastMessage[];
};

const initialState: AppState = {
  modal: null,
  drawer: null,
  toastMessages: [],
};

export const appReducer = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setModal: (state, action: PayloadAction<Modal | null>) => {
      state.modal = action.payload;
    },
    updateModal: (state, action: PayloadAction<Pick<Modal, 'size' | 'onBack'>>) => {
      state.modal = state.modal ? { ...state.modal, ...action.payload } : null;
    },
    dismissModal: (state) => {
      state.modal = null;
    },
    setDrawer: (state, action: PayloadAction<Drawer | null>) => {
      state.drawer = action.payload;
    },
    updateDrawer: (state, action: PayloadAction<Pick<Drawer, 'size' | 'onBack'>>) => {
      state.drawer = state.drawer ? { ...state.drawer, ...action.payload } : null;
    },
    dismissDrawer: (state) => {
      state.modal = null;
    },
    addToastMessage: (state, action: PayloadAction<PartiallyOptional<ToastMessage, 'id'>>) => {
      state.toastMessages = [
        { ...action.payload, id: Math.floor(Math.random() * Math.pow(9, 9) + 1) },
        ...state.toastMessages,
      ];
    },
    removeToastMessage: (state, action: PayloadAction<number>) => {
      state.toastMessages = state.toastMessages.filter((t) => t.id !== action.payload);
    },
  },
});

export const {
  setModal,
  updateModal,
  dismissModal,
  setDrawer,
  updateDrawer,
  dismissDrawer,
  addToastMessage,
  removeToastMessage,
} = appReducer.actions;

export const selectModal = (state: RootState) => state.app.modal;

export const selectDrawer = (state: RootState) => state.app.drawer;

export const selectToastMessages = (state: RootState) => state.app.toastMessages;

export default appReducer.reducer;
