// import clsx from 'clsx';
// import { Listbox, Popover, Transition } from '@headlessui/react';
// import { ChevronDownIcon } from '@heroicons/react/24/solid';
// import { StoredWallet, TransactionDisplay, transactionTypes } from '../../types';
// import { truncateMiddle } from '../../Utils/truncateMiddle';
// import { useState, useEffect, ChangeEventHandler } from 'react';
// import Button from '../../UI/button/button';
// import { DayPicker } from 'react-day-picker';
// import 'react-day-picker/dist/style.css';
// import { format, isValid, parse, getUnixTime, startOfDay, parseISO } from 'date-fns';
// import { usePopper } from 'react-popper';
// import { chainIds } from '../../Api/blockpit';

// const FilterRow = ({ id, onChange }: { id: number; onChange: (data: ActiveFilter) => void }) => {
//   const [selectedFilterType, setSelectedFilterType] = useState<FilterType>('Date');
//   const [selectedRuleType, setSelectedRuleType] = useState<RuleType>(ruleTypes[0]);
//   const [filterValue, setFilterValue] = useState<string | number>('');
//   const [activeFilter, setActiveFilter] = useState<ActiveFilter | null>(null);
//   const [inputType, setInputType] = useState<InputType>('text');

//   useEffect(() => {
//     setSelectedRuleType(ruleTypes[0]);
//   }, [selectedFilterType]);

//   useEffect(() => {
//     if (activeFilter) {
//       onChange(activeFilter);
//     }
//   }, [activeFilter]);

//   useEffect(() => {
//     switch (selectedFilterType) {
//       case 'Date':
//         setInputType('date');
//         break;
//       case 'Amount':
//         setInputType('number');
//         break;
//       case 'Type':
//         setInputType('type');
//         break;
//       case 'Blockchain':
//         setInputType('chain');
//         break;
//       default:
//         setInputType('text');
//         break;
//     }
//   }, [selectedFilterType]);

//   return (
//     <div className='flex flex-row items-center justify-between'>
//       <SelectDropdown
//         options={filterTypes.slice()}
//         selected={selectedFilterType}
//         setSelected={setSelectedFilterType}
//       />
//       <SelectDropdown
//         options={ruleTypes
//           .filter((rule) => rule.a.includes(selectedFilterType))
//           .map((rule) => rule.name)}
//         selected={selectedRuleType.name}
//         setSelected={(selected) =>
//           setSelectedRuleType(ruleTypes.find((rule) => rule.name === selected) || ruleTypes[0])
//         }
//       />
//       <FilterInput
//         type={inputType}
//         value={filterValue}
//         onChange={(e) => setFilterValue(e.target.value)}
//       />
//       <Button
//         size='square'
//         variant='square'
//         onClick={() =>
//           setActiveFilter({
//             id,
//             type: selectedFilterType,
//             rule: selectedRuleType,
//             value: filterValue,
//           })
//         }
//       >
//         +
//       </Button>
//     </div>
//   );
// };

// export const Filter = ({
//   tableData,
//   onFilter,
// }: {
//   tableData: TransactionDisplay[];
//   onFilter: (data: TransactionDisplay[]) => void;
// }) => {
//   const [matchType, setMatchType] = useState<'All' | 'Some'>('All');
//   const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([]);
//   const [filterRows, setFilterRows] = useState<number[]>([1]);

//   const updateFilters = (data: ActiveFilter) => {
//     setActiveFilters((filters) => [data, ...filters.filter((filter) => filter.id !== data.id)]);
//   };

//   useEffect(() => {
//     if (!activeFilters.length) {
//       onFilter(tableData);
//     } else {
//       console.log('activeFilters :>> ', activeFilters);
//       let data = tableData;
//       data = filterWalletEqual(data);
//       data = filterWalletNotEqual(data);
//       data = filterAssetEqual(data);
//       data = filterAssetNotEqual(data);
//       data = filterAmountEqual(data);
//       data = filterAmountNotEqual(data);
//       data = filterAmountGreaterThan(data);
//       data = filterAmountLessThan(data);
//       data = filterDateEqual(data);
//       data = filterDateNotEqual(data);
//       data = filterDateGreaterThan(data);
//       data = filterDateLessThan(data);
//       data = filterByChainEqual(data);
//       data = filterByChainNotEqual(data);
//       data = filterByTypeEqual(data);
//       data = filterByTypeNotEqual(data);
//       onFilter(data);
//     }
//   }, [activeFilters]);

//   return (
//     <div className='w-full flex flex-col items-center justify-center'>
//       <div className='flex justify-between'>
//         <div className='gap-2 flex items-center justify-center'>
//           <span>Match</span>
//           <SelectDropdown
//             options={['All', 'Some']}
//             selected={matchType}
//             setSelected={setMatchType}
//           />{' '}
//           Rules
//         </div>
//         <div className='gap-2 flex items-center justify-center'>
//           <Button
//             onClick={() => (setActiveFilters([]), setFilterRows([]))}
//             variant='square'
//             size='square'
//           >
//             Clear All
//           </Button>
//           <Button onClick={() => setFilterRows((rows) => [...rows, rows.length + 1])}>
//             Add Filter
//           </Button>
//         </div>
//       </div>
//       <div className='flex flex-col items-center justify-center'>
//         {filterRows.map((row, idx) => (
//           <FilterRow key={idx} id={row} onChange={updateFilters} />
//         ))}
//       </div>
//     </div>
//   );
// };

import { Filter } from './Filters';

export default Filter;
