import { Fragment, ReactNode } from 'react';
import { Dialog } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../Redux';
import { selectDrawer, setDrawer } from '../../Redux/appReducer';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { NavLink } from 'react-router-dom';
// import { routes } from '../../layout/Menu';
import Button from '../button';
// import Logo from '../../Components/Logo';
import clsx from 'clsx';
import { routes } from '../../layout/Header';
// import { routes } from '../..';

export type DrawerSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'auto' | 'tiny';

export type Drawer = {
  title?: string;
  content: () => ReactNode;
  onBack?: () => void;
  size?: DrawerSize;
  disableClose?: boolean;
};

const DrawerContainer = () => {
  const dispatch = useDispatch();
  const drawer = useAppSelector(selectDrawer);

  return (
    <Transition appear show={!!drawer} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-[99999999] overflow-hidden'
        onClose={() => dispatch(setDrawer(null))}
      >
        {drawer && (
          <>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-700 bg-opacity-60 backdrop-blur' />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter='transform transition ease-out duration-300'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transform transition ease-in duration-300'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <div className='fixed inset-y-0 left-0 flex w-full max-w-full xs:w-auto'>
                <aside
                  className={clsx(
                    'top-0 z-40 h-full w-full max-w-full border-dashed left-0 border-r border-gray-700 bg-dark xs:w-80 xl:fixed xl:w-72 2xl:w-80'
                  )}
                >
                  <div className='relative flex h-24 items-center justify-between overflow-hidden p-4 2xl:px-8'>
                    <div className='mt-[-0.925rem]'>
                      <a href='#'>
                        <img className='w-36' src='/images/BananaBoardLogo.svg' alt='logo' />
                      </a>
                    </div>
                    <div className='md:hidden -mt-2.5'>
                      <Button
                        title='Close'
                        color='primary'
                        shape='circle'
                        variant='transparent'
                        size='small'
                        onClick={() => dispatch(setDrawer(null))}
                      >
                        <XMarkIcon className='h-auto w-3.5 text-white' />
                      </Button>
                    </div>
                  </div>
                  <div className='px-4 pb-5 2xl:px-8'>
                    <div className='font-console mt-2 flex flex-col gap-4'>
                      {routes.map((item, index) => (
                        <NavLink
                          key={index}
                          to={item.path}
                          onClick={() => dispatch(setDrawer(null))}
                          className={({ isActive }) => {
                            const classes = [
                              'text-base] font-medium uppercase transition text-gray-300 hover:text-white',
                            ];
                            if (isActive) classes.push('!text-white');
                            return clsx(classes);
                          }}
                        >
                          {item.label}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </aside>
              </div>
            </Transition.Child>
          </>
        )}
      </Dialog>
    </Transition>
  );
};

export default DrawerContainer;
