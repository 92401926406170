import { useState, useEffect, ReactElement } from 'react';
import { Transaction, TransactionMember } from '../../types';
import { useAppSelector } from '../../Redux/index';
import { selectAllWallets } from '../../Redux/walletReducer';
import {
  walletTransactions,
  TransactionResponse,
  ChainID,
  rejectDelay,
  Response,
} from '../../Api/blockpit';
import { flatten, isEqual, orderBy, reverse, uniqBy } from 'lodash';
import Loader from '../../UI/loader';
import TransactionsTable from './Table';
import TransactionsTable1 from './Table1';
import { TransactionDisplay } from '../../types/index';
import { format, getUnixTime, parseISO, startOfDay } from 'date-fns';
import Skeleton from '../../UI/skeleton/no-wallet';
import { ethers } from 'ethers';
import { maxDecimals } from '../../Utils/number';
import { truncateMiddle } from '../../Utils/truncateMiddle';
import { AllTypes } from './uniq';
import data from './data.json';
import datar from './data-r.json';
import clsx from 'clsx';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Empty } from './Empty';
import Button from '../../UI/button/button';
import { useModal } from '../../Hooks/useModal';
import { fromUnixTime } from 'date-fns/esm';
import { chains } from '../../constants';
import { blockpitApi } from '../../Api';
import { config } from '../../config';

const parseTokenBalance = (balance: number | null) => {
  return balance && balance > 0
    ? ethers.utils.formatEther(balance.toLocaleString('fullwide', { useGrouping: false }))
    : 0;
};

// const dataParsed = uniqBy(AllTypes, (a) => a);
// console.log(dataParsed);

const txValuesUTXO = (
  from: string,
  to: string,
  txFee: string
): {
  token: string | string[];
  quantity: string | number | number[] | string[];
  fee: { symbol: string; quantity: number } | null;
} => {
  return {
    token: '',
    quantity: '',
    fee: { symbol: '', quantity: 12 },
  };
};

const txValues = (
  from: TransactionMember,
  to: TransactionMember,
  txFee: TransactionMember
): {
  token: string | string[];
  quantity: string | number | number[] | string[];
  fee: { symbol: string; quantity: number } | null;
} => {
  const tFrom = from.currency_symbol;
  const tTo = to.currency_symbol;
  const tokens = !!tFrom && !!tTo ? [tFrom, tTo] : tFrom || tTo || 'UNK';
  const token = tokens.length > 1 && tokens[0] === tokens[1] ? tokens[0] : tokens;

  const qFrom = maxDecimals(from.quantity / 10 ** from.decimals);
  const qTo = maxDecimals(to.quantity / 10 ** to.decimals);
  const q = !!qFrom && !!qTo ? [qFrom, qTo] : qFrom || qTo || 0;
  const quantity = q instanceof Array && q?.length > 1 && q[0] === q[1] ? q[0] : q;

  const feeQuantity = txFee !== null ? txFee.quantity / 10 ** txFee.decimals : null;
  const fee = feeQuantity ? { symbol: txFee.currency_symbol, quantity: feeQuantity } : null;

  return { token, quantity, fee };
};

export const truncateOver = (str: string, over = 15): string => {
  if (!str) return '';
  return str.length > over ? truncateMiddle(str, over, '...') : str;
};

// const txDescription = (tr: Transaction): string | ReactElement => {
//   const date = format(parseISO(tr.timestamp), 'd MMM yy, h:mm a');
//   const from = tr.from[0].address;
//   const to = tr.to[0].address;
//   const { token, quantity, fee } = txValues(tr.from[0], tr.to[0], tr.fee);
//   if (tr.transaction_type === 'deposit') {
//     console.log('token', token, 'quantity', quantity, 'fee', fee);
//     return (
//       <>
//         <div className='inline'>
//           Sent{' '}
//           <span className='text-red'>
//             {quantity} {token}
//           </span>{' '}
//           to{' '}
//           <a
//             href={`https://etherscan.io/tx/${to}`}
//             target='_blank'
//             className='text-gray-400 hover:text-gray-300 cursor-pointer'
//           >
//             {truncateMiddle(to, 15, '...')}
//           </a>
//         </div>
//       </>
//     );
//   } else if (tr.transaction_type === 'withdrawal') {
//     return (
//       <>
//         <div className='inline'>
//           Received{' '}
//           <span className='text-green'>
//             {quantity} {token}
//           </span>{' '}
//           from{' '}
//           <a
//             href={`https://etherscan.io/tx/${from}`}
//             target='_blank'
//             className='text-gray-400 hover:text-gray-300 cursor-pointer'
//           >
//             {truncateMiddle(from, 15, '...')}
//           </a>
//         </div>
//       </>
//     );
//   } else if (tr.transaction_type === 'trade') {
//     return (
//       <>
//         <div className='inline'>
//           Traded{' '}
//           <span className='text-orange'>
//             {Array.isArray(quantity) ? quantity[0] : quantity}{' '}
//             {Array.isArray(token) ? truncateOver(token[0]) : truncateOver(token)}
//           </span>{' '}
//           {Array.isArray(quantity) && Array.isArray(token) && (
//             <>
//               for{' '}
//               <span className='text-orange'>
//                 {quantity[1]} {truncateOver(token[1])}
//               </span>
//             </>
//           )}
//         </div>
//       </>
//     );
//   } else if (tr.transaction_type === 'fee') {
//     return (
//       <>
//         <div className='inline'>
//           Called function on <span className='text-orange'>{truncateOver(to)}</span> for a fee of{' '}
//           <span className='text-orange'>
//             {quantity[0]} {Array.isArray(token) ? truncateOver(token[0]) : truncateOver(token)}
//           </span>
//         </div>
//       </>
//     );
//   } else if (tr.transaction_type === 'staking_reward') {
//     return (
//       <>
//         <div className='inline'>
//           Received staking reward of{' '}
//           <span className='text-green'>
//             {quantity} {token}
//           </span>{' '}
//           from{' '}
//           <a
//             href={`https://etherscan.io/tx/${from}`}
//             target='_blank'
//             className='text-gray-400 hover:text-gray-300 cursor-pointer'
//           >
//             {truncateMiddle(from, 15, '...')}
//           </a>
//         </div>
//       </>
//     );
//   }
//   return 'Unknown transaction type';
// };

const describeTransaction = (transaction: {
  transaction_type: string;
  from: {
    address: string;
    quantity: number;
    decimals: number;
    currency: string;
    currency_name: string;
    currency_symbol: string;
  }[];
  to: {
    address: string;
    quantity: number;
    decimals: number;
    currency: string;
    currency_name: string;
    currency_symbol: string;
  }[];
}) => {
  let action: string;
  switch (transaction.transaction_type) {
    case 'deposit':
      action = 'received';
      break;
    case 'withdrawal':
      action = 'withdrew';
      break;
    case 'fee':
      action = 'paid a fee for';
      break;
    default:
      action = 'transferred';
      break;
  }
  const fromAddress = transaction.from[0].address;
  const toAddress = transaction.to[0].address;
  const quantity = transaction.from[0].quantity / 10 ** transaction.from[0].decimals;
  const currency = transaction.from[0].currency_symbol;
  return `${fromAddress} ${action} ${quantity} ${currency} to ${toAddress}`;
};

const describeTransactionFee = (quantity: number, decimals: number, currency_symbol: string) => {
  if (quantity && decimals && currency_symbol) {
    const amount = quantity / 10 ** decimals;
    const token = currency_symbol;
    return { amount, token, value: amount };
  } else {
    return { amount: 0, token: '', value: 0 };
  }
};

const getTxDescriptionColor = (tx: any, wallet?: string, isUTXO?: boolean) => {
  if (!wallet || !wallet.length) console.log('no wallet');

  if (isUTXO) {
    if (tx.transaction_type === 'deposit') return 'text-tr-green';
    if (tx.transaction_type === 'withdrawal') return 'text-tr-red';
    return 'text-tr-blue';
  }

  const from =
    !!tx.from &&
    !!tx.from.length &&
    tx.from.some((f) => !!f.address && f.address.toLowerCase() === wallet!.toLowerCase());
  const to =
    !!tx.to &&
    !!tx.to.length &&
    tx.to.some((f) => !!f.address && f.address.toLowerCase() === wallet!.toLowerCase());
  // const to = tx.to.some((tx) => tx.to.address.toLowerCase() === wallet!.toLowerCase());
  return from ? 'text-tr-red' : to ? 'text-tr-green' : 'text-tr-blue';
  // const;
  // const color = wallet
  //   ? from.toLowerCase() === wallet.toLowerCase()
  //     ? 'text-tr-red'
  //     : 'text-tr-green'
  //   : 'text-tr-blue';

  // let color = '';
  // if (wallet) {
  //   if (from) {
  //     if (from.toLowerCase() === wallet.toLowerCase()) {
  //       color = 'text-tr-red';
  //     } else if (to) {
  //       if (to.toLowerCase() === wallet.toLowerCase()) {
  //         color = 'text-tr-green';
  //       } else {
  //         color = 'text-tr-blue';
  //       }
  //     }
  //   } else {
  //     color = 'text-tr-blue';
  //   }
  // }
};
const getTransactionDescription = (
  transaction: any,
  token: string | string[],
  quantity: string | number | number[] | string[],
  wallet?: string,
  isUTXO?: boolean
  // fee: { symbol: string; quantity: number } | null
): ReactElement => {
  if (!wallet) console.log(transaction);
  let action = '';
  let highlight = 'text-tr-green';
  switch (transaction.kyt_pattern_label) {
    case 'Native_Transfer':
      action = transaction.from[0]?.identifier?.name
        ? 'Sent'
        : transaction.transaction_type === 'deposit'
        ? 'Received'
        : 'Sent';
      highlight = 'text-tr-blue'; // DONE
      break;
    case 'Fallback':
      action = transaction.from[0]?.identifier?.name
        ? 'Sent'
        : transaction.transaction_type === 'deposit'
        ? 'Received'
        : 'Sent';
      highlight = 'text-tr-blue'; // DONE
      break;
    case 'Failed_Transaction':
      action = 'Failed transaction';
      highlight = 'text-tr-red'; // DONE
      break;
    case 'Fee_Transaction':
      action = 'Paid a fee for';
      highlight = 'text-tr-blue'; // DONE
      break;
    case 'Fallback_Mint':
      action = 'Minted';
      highlight = 'text-tr-green'; // DONE
      break;
    case 'OpenSeaV1_Trade':
      action = 'traded NFT';
      highlight = 'text-tr-green'; // DONE
      break;
    case 'ERC20_Approve':
      action = 'Approved';
      highlight = 'text-tr-blue'; // DONE
      break;
    case 'WETH9_UnwrapWETH':
      action = 'Unwrapped WETH';
      highlight = 'text-tr-blue'; // DONE
      break;
    case 'Fallback_Claim':
      action = 'Claimed';
      highlight = 'text-tr-green'; // DONE
      break;
    case 'OpenSeaV1_CancelOrder':
    case 'CancelOrder':
      action = 'Cancelled NFT order';
      highlight = 'text-tr-red'; // DONE
      break;
    case 'Erc1155_SafeTransferFrom':
      action = 'Sent ERC1155 token';
      highlight = 'text-tr-yellow'; // DONE
      break;
    case 'ERC721_TransferFrom':
      action = 'Traded NFT';
      highlight = 'text-tr-yellow'; // DONE
      break;
    case 'Fallback_Trade':
      action = 'Traded';
      highlight = 'text-tr-yellow'; // DONE
      break;
    case 'UniV3Family_Trade':
      action = 'Traded';
      highlight = 'text-tr-yellow'; // DONE
      break;
    case 'WETH9_WrapETH':
      action = 'Wrapped ETH';
      highlight = 'text-tr-blue'; // DONE
      break;
    case 'ERC20_Transfer':
      action = 'Sent ERC20 token';
      highlight = 'text-tr-yellow'; // DONE
      break;
    case 'UniV2Family_Trade':
      action = 'Traded';
      highlight = 'text-tr-yellow'; // DONE
      break;
    case 'OpenSeaV2_Trade':
      action = 'Traded NFT';
      highlight = 'text-tr-yellow'; // DONE
      break;
  }
  if (!action || action === '') {
    switch (transaction.transaction_type) {
      case 'deposit':
        action = 'Received';
        highlight = 'text-tr-blue'; // DONE
        break;
      case 'withdrawal':
        action = 'Sent';
        highlight = 'text-tr-blue'; // DONE
        break;
      case 'staking_reward':
        action = 'Received staking reward';
        highlight = 'text-tr-green'; // DONE
        break;
      case 'lending_reward':
        action = 'Received lending reward';
        highlight = 'text-tr-green'; // DONE
        break;
      case 'airdrop':
        action = 'Airdropped';
        highlight = 'text-tr-green'; // DONE
        break;
      case 'trade':
        action = 'Traded';
        highlight = 'text-tr-blue'; // DONE
        break;
      case 'fee':
        action = 'Paid a fee for';
        highlight = 'text-tr-blue'; // DONE
        break;
      default:
        action = '';
    }
  }
  const from = transaction.from[0].address;
  const to = transaction.to[0].address;
  // const color = wallet
  //   ? from.toLowerCase() === wallet.toLowerCase()
  //     ? 'text-tr-red'
  //     : 'text-tr-green'
  //   : 'text-tr-blue';

  if (transaction.transaction_type === 'trade') {
    if (transaction.from.length > 1) {
      const fromTokens = transaction.from.map((t: any, i) => {
        return (
          <>
            <span className='text-tr-red font-bold'>
              {maxDecimals(t.quantity / 10 ** t.decimals) ?? 0}{' '}
              {truncateOver(t.currency_symbol ?? t.currency_name)}
            </span>
            {i < transaction.to.length - 1 ? ' and ' : ''}
          </>
        );
      });
      const toToken = (
        <span className='text-tr-green font-bold'>
          {quantity[1]} {truncateOver(token[1])}
        </span>
      );
      return (
        <>
          {action} {fromTokens} for {toToken}
        </>
      );
    }
    if (transaction.to.length > 1) {
      const fromToken = (
        <span className='text-tr-red font-bold'>
          {quantity[0]} {truncateOver(token[0])}
        </span>
      );
      const toTokens = transaction.to.map((t: any, i) => {
        return (
          <>
            <span className='text-tr-green font-bold'>
              {maxDecimals(t.quantity / 10 ** t.decimals) ?? 0}{' '}
              {truncateOver(t.currency_symbol ?? t.currency_name)}
            </span>
            {i < transaction.to.length - 1 ? ' and ' : ''}
          </>
        );
      });

      return (
        <>
          {action} {fromToken} for {toTokens}
        </>
      );
    }
  }

  const color = getTxDescriptionColor(transaction, wallet, isUTXO);
  // let color = '';
  // if (wallet) {
  //   if (from) {
  //     if (from.toLowerCase() === wallet.toLowerCase()) {
  //       color = 'text-tr-red';
  //     } else if (to) {
  //       if (to.toLowerCase() === wallet.toLowerCase()) {
  //         color = 'text-tr-green';
  //       } else {
  //         color = 'text-tr-blue';
  //       }
  //     }
  //   } else {
  //     color = 'text-tr-blue';
  //   }
  // }
  // const quantity = (
  //   transaction.from[0].quantity /
  //   10 ** transaction.from[0].decimals
  // ).toLocaleString(undefined, { maximumFractionDigits: 4 });
  const symbol = truncateOver(transaction.from[0].currency_symbol, 10);
  const qDisplay = Array.isArray(quantity) ? quantity.join(', ') : quantity;
  const textColor = highlight;
  // const textColor = `text-${highlight}`;
  let tDisplay;
  // If both quantity and token are an array, we need to display them separately
  if (Array.isArray(token) && Array.isArray(quantity)) {
    const fromToken = (
      <span className='text-tr-red font-bold'>
        {quantity[0]} {truncateOver(token[0])}
      </span>
    );
    const toToken = (
      <span className='text-tr-green font-bold'>
        {/* <span className={clsx('font-bold', color)}> */}
        {quantity[1]} {truncateOver(token[1])}
      </span>
    );
    tDisplay = (
      <>
        {action} {fromToken} for {toToken}
      </>
    );
  } else if (Array.isArray(token)) {
    tDisplay = (
      <>
        {action}{' '}
        <span className='text-tr-red font-bold'>
          {/* <span className={clsx('font-bold', color)}> */}
          {quantity} {truncateOver(token[0])}
        </span>{' '}
        and <span className='text-tr-green font-bold'>{truncateOver(token[1])}</span>
        {/* and <span className={clsx('font-bold', color)}>{truncateOver(token[1])}</span> */}
      </>
    );
  } else if (Array.isArray(quantity)) {
    tDisplay = (
      <>
        {action}{' '}
        <span className={clsx('font-bold', color)}>
          {quantity[0]} {truncateOver(token)}
        </span>{' '}
        and{' '}
        <span className={clsx('font-bold', color)}>
          {quantity[1]} {truncateOver(token)}
        </span>
      </>
    );
  } else {
    const accLink = getAccountLink(transaction.chain, action === 'Sent' ? to : from);
    tDisplay = (
      <>
        {action}{' '}
        <span className={clsx('font-bold', color)}>
          {quantity > Math.pow(10, 15) ? quantity.toString() : quantity} {truncateOver(token)}
        </span>
        {action === 'Sent' && (
          <span className={clsx('font-bold text-white/70')}>
            {' '}
            to{' '}
            <a
              href={accLink}
              target='_blank'
              className='text-gray-400 hover:text-gray-300 cursor-pointer'
            >
              {truncateMiddle(to, 15, '...')}
            </a>
          </span>
        )}
        {action === 'Received' && (
          <span className={clsx('font-bold text-white/70')}>
            {' '}
            from{' '}
            <a
              href={accLink}
              target='_blank'
              className='text-gray-400 hover:text-gray-300 cursor-pointer'
            >
              {truncateMiddle(to, 15, '...')}
            </a>
          </span>
        )}
      </>
    );
  }

  return <div>{tDisplay}</div>;
  // return `${tDisplay} from ${from} to ${to}`;
};

const getTransactionLink = (chain: ChainID, hash: string) => {
  return chains.find((c) => c.id === chain)?.explorerTx + '/' + hash;
};

const getAccountLink = (chain: ChainID, account: string) => {
  return chains.find((c) => c.id === chain)?.explorerAcc + '/' + account;
};

const txDisplay = (t: Transaction & { chain: ChainID }): TransactionDisplay => {
  const { token, quantity, fee } =
    typeof t.fee === 'string'
      ? {
          token: t.quantity_currency,
          quantity: Number(t.quantity) / 10 ** 8,
          fee: { symbol: t.fee_currency, quantity: Number(t.fee) / 10 ** 8 },
        }
      : txValues(t.from[0], t.to[0], t.fee);
  const isUTXO = typeof t.timestamp === 'number';
  const txDate = isUTXO ? fromUnixTime(Number(t.timestamp)) : parseISO(t.timestamp);
  return {
    transaction: {
      date: format(txDate, 'dd.MM.yyyy, HH:mm:ss'),
      day: getUnixTime(startOfDay(txDate)),
      timestamp: getUnixTime(txDate),
      hash: t?.transactionhash ?? t.txid,
      chain: t.chain,
      wallets: [t.from[0].address, t.to[0].address],
      assets: flatten([token]),
      amounts: flatten([quantity]),
      type: t.transaction_type,
      link: getTransactionLink(t.chain, t.transactionhash ?? t.txid),
    },
    details: getTransactionDescription(t, token, quantity, t.wallet, isUTXO),
    // fee: { amount: 0, token: '', value: 0 },
    fee:
      typeof t.fee == 'object' && t.fee !== null
        ? describeTransactionFee(t.fee.quantity, t.fee.decimals, t.fee.currency_symbol)
        : { amount: Number(t.fee) / 10 ** 8, token: t.fee_currency, value: 0 },
    data: { token, quantity, fee },
    // raw: t,
    // {
    //   amount: t?.fee?.[0]?.quantity || null,
    //   token: 'ETH',
    //   value: t?.fee?.quantity ? Number(parseTokenBalance(t?.fee?.quantity)) : 0,
    // },
  };
};

const History = () => {
  const [transactions, setTransactions] = useState<TransactionDisplay[]>([]);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const wallets = useAppSelector(selectAllWallets, isEqual);
  const modal = useModal();

  const runIt = (queryTransactions) => {
    console.log('queryTransactions', queryTransactions);
    //const queryTransactions = await walletTransactions(wallets);
    if (queryTransactions) {
      const fetchedTransactions = flatten(
        queryTransactions.map((t) =>
          t.transactions.map((tx) => ({ ...tx, chain: t.chain, wallet: t.wallet }))
        )
      );
      // console.log('fetchedTransactions', fetchedTransactions);
      const displayTransactions: TransactionDisplay[] = fetchedTransactions.map((t: any, i) =>
        txDisplay(t)
      );
      setTransactions(orderBy(displayTransactions, (t) => t.transaction.timestamp, 'desc'));
      // setTransactions(reverse(displayTransactions));
      setLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      if (wallets.length > 0) {
        setLoading(true);
        // const queryTransactions = await walletTransactions(wallets);
        // ! PARSED
        // const queryTransactions = data;
        // if (queryTransactions) {
        //   // console.log('queryTransactions', queryTransactions);
        //   // const fetchedTransactions = flatten(
        //   //   queryTransactions.map((t) => t.transactions.map((tx) => ({ ...tx, chain: t.chain })))
        //   // );
        //   // const fetchedTransactions = queryTransactions;
        //   // const displayTransactions: TransactionDisplay[] = fetchedTransactions.map((t: any, i) =>
        //   //   txDisplay(t)
        //   // );
        //   // console.log(displayTransactions.length);
        //   setTransactions(reverse(data as any));
        //   // setTransactions(reverse(displayTransactions));
        //   setLoading(false);
        // }

        // ! BEFORE PARSING
        // const queryTransactions = datar;
        // if (queryTransactions) {
        //   const fetchedTransactions = flatten(
        //     queryTransactions.map((t) => t.transactions.map((tx) => ({ ...tx, chain: t.chain })))
        //   );
        //   const displayTransactions: TransactionDisplay[] = fetchedTransactions.map((t: any, i) =>
        //     txDisplay(t)
        //   );
        //   setTransactions(reverse(displayTransactions));
        //   setLoading(false);
        // }

        // ! ORIGINAL
        // const queryTransactions = await walletTransactions(wallets);
        // if (queryTransactions) {
        //   console.log(
        //     'here',
        //     flatten(
        //       queryTransactions.map((t) => t.transactions.map((tx) => ({ ...tx, chain: t.chain })))
        //     ).length
        //   );
        // }
        // const fetchedTransactions = data;
        // console.log('fetchedTransactions', fetchedTransactions);
        // const displayTransactions: TransactionDisplay[] = fetchedTransactions.map((t: any, i) =>
        //   txDisplay(t)
        // );
        // setTransactions(orderBy(displayTransactions, (t) => t.transaction.day, 'desc'));
        // // setTransactions(reverse(displayTransactions));
        // setLoading(false);
        // //}
        const tx: any[] = [];
        const res = await Promise.allSettled(
          flatten(
            wallets
              .filter((a) => a.validFor.length)
              .map((wallet) =>
                wallet.validFor.map((chain) =>
                  blockpitApi
                    .get<Response<Transaction[]>>(
                      `/${chain}/GetTransactions?address=${wallet.address}`
                      // params ?? {}
                    )
                    .then((r) => {
                      const single = {
                        chain,
                        transactions: r?.data?.result?.map((t) => ({ ...t, chain })),
                        wallet: wallet.address,
                      };
                      if (!!single.transactions && single.transactions.length) {
                        tx.push(single);
                        runIt(tx);
                      }
                      return {
                        chain,
                        transactions: r?.data?.result?.map((t) => ({ ...t, chain })),
                        wallet: wallet.address,
                      };
                    })
                    .catch(() => ({ chain, transactions: false }))
                )
              )
          ).map((p) => Promise.race([p, rejectDelay(config.timeout)]))
        );
        setFinished(true);
        // TODO if getting transactions as they come in works, remove the below, otherwise bring it back
        // runIt(res);
        // const queryTransactions = await walletTransactions(wallets);
        // if (queryTransactions) {
        //   const fetchedTransactions = flatten(
        //     queryTransactions.map((t) =>
        //       t.transactions.map((tx) => ({ ...tx, chain: t.chain, wallet: t.wallet }))
        //     )
        //   );
        //   // console.log('fetchedTransactions', fetchedTransactions);
        //   const displayTransactions: TransactionDisplay[] = fetchedTransactions.map((t: any, i) =>
        //     txDisplay(t)
        //   );
        //   setTransactions(orderBy(displayTransactions, (t) => t.transaction.day, 'asc'));
        //   // setTransactions(reverse(displayTransactions));
        //   setLoading(false);
        // }
      }
    })();
    //TODO clean it up when unmounted
  }, [wallets]);

  if (!wallets.length) {
    return <Skeleton message='Connect a wallet first' />;
  }
  return (
    <>
      <div className='rounded-lg bg-light-dark overflow-hidden shadow'>
        <div className='bg-light-dark p-6'>
          <div className='sm:flex sm:items-center sm:justify-between'>
            <div className='sm:flex sm:space-x-5'>
              <div className='-mt-1 text-center sm:mt-0 sm:pt-1 sm:text-left'>
                <p className='text-sm font-medium text-gray-400'>Total Transactions</p>
                <p className='text-xl font-bold text-white sm:text-2xl'>
                  {loading ? (
                    <Loader variant='blink' size='small' className='mt-4 ml-0.5' />
                  ) : (
                    <>
                      {transactions.length}{' '}
                      {!finished && (
                        <Loader
                          variant='blink'
                          size='small'
                          className='mt-0 ml-1 inline-flex align-[5px]'
                        />
                      )}
                    </>
                  )}
                </p>{' '}
              </div>
            </div>
            {/* <Button
              size='mini'
              color='info'
              onClick={() => {
                modal.show({ content: () => <>Tax</> });
              }}
            >
              Tax API
            </Button> */}
          </div>
        </div>
        {/* <div>
          <dl className='grid grid-cols-1 sm:grid-cols-3 border-t border-gray-800 bg-light-dark'>
            <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6 border-r border-gray-800'>
              <dt className='text-sm font-medium text-gray-400 truncate'>Avg. Gas Paid</dt>
              <dd className='mt-1 text-xl font-semibold text-white'>
                {loading ? (
                  <Loader variant='blink' size='small' className='mt-4 ml-0.5' />
                ) : (
                  <>$1.92</>
                )}
              </dd>
            </div>

            <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6 border-r border-gray-800'>
              <dt className='text-sm font-medium text-gray-400 truncate'>Total Gas Paid</dt>
              <dd className='mt-1 text-xl font-semibold text-white'>
                {loading ? <Loader variant='blink' size='small' className='mt-4 ml-0.5' /> : <>-</>}
              </dd>
            </div>

            <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6'>
              <dt className='text-sm font-medium text-gray-400 truncate'>Total Gas Paid (USD)</dt>
              <dd className='mt-1 text-xl font-semibold text-white'>
                {loading ? <Loader variant='blink' size='small' className='mt-4 ml-0.5' /> : <>-</>}
              </dd>
            </div>
          </dl>
        </div> */}
      </div>
      {/* <div className='rounded-lg bg-light-dark overflow-hidden shadow min-h-[104px]'>
        <div className='bg-light-dark p-6'>
          <div className='sm:flex sm:items-center sm:justify-between'>
            <div className='sm:flex sm:space-x-5'>
              <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
                <p className='text-sm font-medium text-gray-400'>Total Transactions</p>
                <p className='text-xl font-bold text-white sm:text-2xl'>
                  {loading ? (
                    <Loader variant='blink' size='small' className='mt-4 ml-0.5' />
                  ) : (
                    transactions.length
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className='rounded-lg bg-light-dark overflow-hidden shadow min-h-[250px]'>
        {loading ? (
          <div className='h-full flex w-full justify-center items-center'>
            <Loader showOnlyThreeDots variant='blink' />
          </div>
        ) : (
          <>
            <section aria-labelledby=''>
              <div className='h-full'>
                {/* <TransactionsTable tableData={transactions} /> */}
                {transactions.length ? <TransactionsTable1 tableData={transactions} /> : <Empty />}
              </div>
            </section>
          </>
        )}
      </div>
    </>
  );
};

export default History;
