import { Currency } from './types';

import * as XRPValidator from './validators/ripple_validator';
import * as ETHValidator from './validators/ethereum_validator';
import * as COSMOSValidator from './validators/cosmos_validator';
import * as TERRAValidator from './validators/terra_validator';
import * as OSMOSISValidator from './validators/osmosis_validator';
import * as CRYPTOORGValidator from './validators/cryptoorg_validator';
import * as BTCValidator from './validators/bitcoin_validator';
import * as ADAValidator from './validators/ada_validator';
import * as TRXValidator from './validators/tron_validator';
import * as BCHValidator from './validators/bch_validator';
import * as LTCValidator from './validators/litecoin_validator';
import * as DOGEValidator from './validators/doge_validator';

// defines P2PKH and P2SH address types for standard (prod) and testnet networks
const CURRENCIES: Currency[] = [
  {
    name: 'Bitcoin',
    symbol: 'btc',
    addressTypes: { prod: ['00', '05'], testnet: ['6f', 'c4', '3c', '26'] },
    bech32Hrp: { prod: ['bc'], testnet: ['tb'] },
    validator: BTCValidator.isValidAddress,
  },
  {
    name: 'BitcoinCash',
    symbol: 'bch',
    regexp: new RegExp('^[qQpP]{1}[0-9a-zA-Z]{41}$'),
    addressTypes: { prod: ['00', '05'], testnet: ['6f', 'c4'] },
    validator: BCHValidator.isValidAddress,
  },
  {
    name: 'LiteCoin',
    symbol: 'ltc',
    addressTypes: { prod: ['30', '05', '32'], testnet: ['6f', 'c4', '3a'] },
    bech32Hrp: { prod: ['ltc'], testnet: ['tltc'] },
    validator: LTCValidator.isValidAddress,
  },
  {
    name: 'DogeCoin',
    symbol: 'doge',
    addressTypes: { prod: ['1e', '16'], testnet: ['71', 'c4'] },
    validator: DOGEValidator.isValidAddress,
  },
  {
    name: 'Ripple',
    symbol: 'xrp',
    validator: XRPValidator.isValidAddress,
  },
  {
    name: 'Ethereum',
    symbol: 'eth',
    validator: ETHValidator.isValidAddress,
  },
  {
    name: 'EthereumClassic',
    symbol: 'etc',
    validator: ETHValidator.isValidAddress,
  },
  {
    name: 'EthereumPow',
    symbol: 'ethw',
    validator: ETHValidator.isValidAddress,
  },
  {
    name: 'Fantom',
    symbol: 'ftm',
    validator: ETHValidator.isValidAddress,
  },
  {
    name: 'Klaytn',
    symbol: 'klay',
    validator: ETHValidator.isValidAddress,
  },
  {
    name: 'Cardano',
    symbol: 'ada',
    bech32Hrp: { prod: ['addr'], testnet: ['addr'] },
    validator: ADAValidator.isValidAddress,
  },
  {
    name: 'Cosmos',
    symbol: 'atom',
    validator: COSMOSValidator.isValidAddress,
  },
  {
    name: 'Osmosis',
    symbol: 'osmo',
    validator: OSMOSISValidator.isValidAddress,
  },
  {
    name: 'TerraClassic',
    symbol: 'luna',
    validator: TERRAValidator.isValidAddress,
  },
  {
    name: 'Terra',
    symbol: 'tera',
    validator: TERRAValidator.isValidAddress,
  },
  {
    name: 'CryptoOrg',
    symbol: 'atom',
    validator: CRYPTOORGValidator.isValidAddress,
  },
  {
    name: 'Velas',
    symbol: 'VLSX',
    validator: ETHValidator.isValidAddress,
  },
  {
    name: 'Polygon',
    symbol: 'MATIC',
    validator: ETHValidator.isValidAddress,
  },
  {
    name: 'Binance',
    symbol: 'bnb',
    validator: ETHValidator.isValidAddress,
  },
  {
    name: 'BinanceSmartChain',
    symbol: 'bsc',
    validator: ETHValidator.isValidAddress,
  },
  {
    name: 'Avalanche',
    symbol: 'avax',
    validator: ETHValidator.isValidAddress,
  },
  {
    name: 'Tron',
    symbol: 'trx',
    addressTypes: { prod: ['65'], testnet: ['160'] },
    validator: TRXValidator.isValidAddress,
  },
];

export const getByNameOrSymbol = (currencyNameOrSymbol: string) => {
  const nameOrSymbol = currencyNameOrSymbol.toLowerCase();
  return CURRENCIES.find(function (currency) {
    return (
      currency.name.toLowerCase() === nameOrSymbol || currency.symbol.toLowerCase() === nameOrSymbol
    );
  });
};
export const getAll = () => {
  return CURRENCIES;
};
