import { blockpitApi, coinGeckoApi } from '.';
import { chains } from '../constants';
import { ParsedBalance, StoredWallet, TokenData, TokenDetails } from '../types';
import { flatten, groupBy } from 'lodash';
import { config } from '../config';
import { ethers } from 'ethers';
import {
  ChainID,
  Response,
  WalletBalance,
  rejectDelay,
  BalancesResponse,
  AvailableAsset,
  BalanceType,
} from './blockpit';

export const walletBalances = async (wallets: StoredWallet[]) => {
  const validChains = wallets.filter((a) => a.validFor.length);
  const chainReqs = validChains.map((wallet) =>
    wallet.validFor.map((chain) =>
      blockpitApi
        .get<Response<BalancesResponse>>(
          `/${chain}/GetAccountBalancesDetailed?address=${wallet.address}`,
          {}
        )
        .then((r) => ({ wallet: wallet.address, chain, balances: r?.data?.result }))
        .catch(() => ({ wallet: wallet.address, chain, balances: false }))
    )
  );
  const requests = flatten(chainReqs).map((p) => Promise.race([p, rejectDelay(config.timeout)]));
  const res = await Promise.allSettled(requests);

  return (
    res
      .filter((r) => r.status === 'fulfilled' && r?.value)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .map((r) => r?.value) as WalletBalance[]
  );
};

// export const tokenPrices = async (wallets: StoredWallet[]) => {
//   const validChains = wallets.filter((a) => a.validFor.length);
//   const chainReqs = validChains.map((wallet) =>
//     wallet.validFor.map((chain) =>
//       blockpitApi
//         .get<Response<BalancesResponse>>(
//           `/${chain}/GetAccountBalancesDetailed?address=${wallet.address}`,
//           {}
//         )
//         .then((r) => ({ wallet: wallet.address, chain, balances: r?.data?.result }))
//         .catch(() => ({ wallet: wallet.address, chain, balances: false }))
//     )
//   );
//   const requests = flatten(chainReqs).map((p) => Promise.race([p, rejectDelay(config.timeout)]));
//   const res = await Promise.allSettled(requests);

//   return (
//     res
//       .filter((r) => r.status === 'fulfilled' && r?.value)
//       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//       // @ts-ignore
//       .map((r) => r?.value) as WalletBalance[]
//   );
// };

// export const getTokensDetails = async (tokensByChain: { chain: ChainID; tokens: string[] }[]) => {
//   const requests = tokensByChain.map((byChain) =>
//     byChain.tokens.map((token) =>
//       blockpitApi
//         .get<Response<TokenDetails>>(`/${byChain.chain}/GetTokenDetails`, {
//           contractAddress: token,
//         })
//         .then((r) => ({ chain: byChain.chain, address: token, token: r?.data?.result }))
//         .catch(() => ({ chain: byChain.chain, address: token, token: false }))
//     )
//   );
//   const res = await Promise.allSettled(
//     flatten(requests).map((p) => Promise.race([p, rejectDelay(config.timeout)]))
//   );

//   return (
//     res
//       .filter((r) => r.status === 'fulfilled' && r.value)
//       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//       // @ts-ignore
//       .map((r) => r.value) as TokenData[]
//   );
// };

// const findNonNativeToken = (b: WalletBalance): { chain: ChainID; tokens: string[] } => {
//   return {
//     chain: b.chain,
//     tokens: Object.keys(b.balances.available).filter((token) => {
//       return chains.find((chain) => chain.id === b.chain && chain.symbol !== token);
//     }),
//   };
// };

// const nonNativeTokens = (balances: WalletBalance[]): { chain: ChainID; tokens: string[] }[] => {
//   const ref = balances.filter((b) => b.balances && Object.entries(b.balances.available).length > 0);
//   return ref.map((b) => findNonNativeToken(b)).filter((b) => b.tokens.length > 0);
// };

const findTokenData = (tokenChain: ChainID, token: string, data: TokenData[]) => {
  const native = chains.find((chain) => chain.id === tokenChain && chain.symbol === token);
  // TODO handle tokens not returning data, like uion on Osmosis
  const meta = native || data.find((t) => t?.token?.contractaddress?.toLowerCase() === token);
  const priceId = native?.coingecko || undefined;
  return { meta, priceId };
};

// const parseTokenBalance = (balance: number | null) => {
//   return balance && balance > 0
//     ? ethers.utils.formatEther(balance.toLocaleString('fullwide', { useGrouping: false }))
//     : 0;
// };

// const mapTokens = (balances: WalletBalance[], tokensData: TokenData[]) => {
//   return balances.map((b) => {
//     const mapped = Object.entries(b.balances.available).map(([tokenId, rawBalance]) => {
//       const { chain, wallet } = b;
//       const isCosmos = ['Osmosis', 'Cosmos'].includes(chain);
//       const balance = !isCosmos ? parseTokenBalance(rawBalance) : rawBalance ?? 0;
//       // TODO #1 tokenId might need to be lowercased here, depending on how the API from coingecko/blockpit handles it
//       // TODO #2 maybe remove this lowercase from tokenId after Osmosis/Cosmos is resolved
//       const tokenMetadata = findTokenData(
//         b.chain,
//         isCosmos ? tokenId.toLowerCase() : tokenId,
//         tokensData
//       );
//       const token = tokenMetadata?.meta?.token;
//       const priceId = tokenMetadata?.priceId;
//       if (token && tokenMetadata?.meta?.icon) token.icon = tokenMetadata.meta.icon;
//       return { chain, wallet, token, balance, priceId };
//     });
//     return mapped;
//   });
// };

// const parseTokens = (balances: WalletBalance[], tokensData: TokenData[]): ParsedBalance[][] => {
//   const withValues = balances.filter((b) => b?.balances?.available);
//   const mapped = mapTokens(withValues, tokensData);
//   return mapped.filter((b) => b !== undefined && b.length);
// };

// const getValueFromPrice = (price: number, balance: number | string) => {
//   const parsedBalance = typeof balance !== 'number' ? parseFloat(balance) : balance;
//   return price * parsedBalance;
// };

// const getPriceByToken = async (tokens: ParsedBalance[]): Promise<ParsedBalance[] | []> => {
//   const chains = tokens.map((t) => t.priceId);
//   const ids = chains.join(',');
//   const vs_currencies = 'usd';
//   const res = await coinGeckoApi.get<{ [k: string]: { usd: number } }>(`/simple/price/`, {
//     ids,
//     vs_currencies,
//   });

//   if (!res.ok) return [];
//   return tokens.map((token) => {
//     const price = (token.priceId && res?.data?.[token.priceId].usd) || 0;
//     return { ...token, price, value: getValueFromPrice(price, token.balance) };
//   });
// };

// const getPriceByContract = async (tokens: ParsedBalance[]): Promise<ParsedBalance[]> => {
//   const withPrices: ParsedBalance[] = [];
//   const groupChain = groupBy(tokens, (t) => t.chain);
//   await Object.entries(groupChain).reduce(async (promise, [chain, tokens]) => {
//     await promise;
//     const findChain = chains.find((c) => c.id === chain)?.platform;
//     const ids = tokens.map((t) => t.token?.contractaddress).join(',');
//     const vs_currencies = 'usd';
//     const res = await coinGeckoApi.get<{ [k: string]: { usd: number } }>(
//       `/simple/token_price/${findChain}`,
//       {
//         contract_addresses: ids,
//         vs_currencies,
//       }
//     );
//     if (!res.ok) return undefined;
//     if (res?.data) {
//       Object.entries(res.data).forEach(([contract, price]) => {
//         // TODO after Cosmos/Osmosis is resolved, check to remove both of these toLowerCase
//         const token = tokens.find(
//           (t) => t.token?.contractaddress.toLowerCase() === contract.toLowerCase()
//         );
//         if (token) {
//           withPrices.push({
//             ...token,
//             price: price.usd,
//             value: getValueFromPrice(price.usd, token.balance),
//           });
//         }
//       });
//     }
//   }, Promise.resolve());
//   return withPrices;
// };

const getImageFromContract = async (chainId: string, contract: string) => {
  const res = await coinGeckoApi.get<{ image: { small: string } }>(
    // TODO after Cosmos/Osmosis is resolved, check to remove this replace
    `/coins/${chainId}/contract/${contract.replace('/', '%2F')}`,
    {}
  );
  if (!res.ok) return undefined;
  if (res?.data) {
    return res?.data?.image?.small;
  }
};

const getTokenImages = async (tokens: ParsedBalance[]) => {
  const withImages: ParsedBalance[] = [];
  const groupChain = groupBy(tokens, (t) => t.chain);
  await Object.entries(groupChain).reduce(async (promise, [chain, tokens]) => {
    await promise;
    const tChain = chains.find((c) => c.id === chain);
    const platform = tChain?.platform;
    await tokens.reduce(async (promise, token) => {
      await promise;
      if (platform && token.token.address && token.token.address.length > 20) {
        const image = await getImageFromContract(platform, token.token.address);
        withImages.push({ ...token, token: { ...token.token, icon: image } });
      } else {
        if (token.token.symbol === token.token.address) {
          const nativeIcon = tChain && tChain.icon;
          withImages.push({ ...token, token: { ...token.token, icon: nativeIcon } });
        } else {
          withImages.push(token);
        }
      }
    }, Promise.resolve());
  }, Promise.resolve());
  return withImages;
};

const balancesByType = async (data: WalletBalance[], type: BalanceType, getImage?: boolean) => {
  let balances: ParsedBalance[] = [];
  data &&
    data.forEach((d) => {
      d.balances &&
        d.balances[type] &&
        d.balances[type].forEach((b) => {
          if (b.prices.USD && b.prices.USD > 0)
            balances.push({
              chain: d.chain,
              wallet: d.wallet,
              token: b.asset,
              balance: b.balance / 10 ** b.asset.decimals,
              priceId: b.asset.symbol,
              prices: b.prices,
              value: (b.balance / 10 ** b.asset.decimals) * b.prices.USD,
            });
        });
    });
  if (getImage) {
    balances = await getTokenImages(balances);
  }
  return balances;
};

export const getWalletBalances = async (wallets: StoredWallet[]) => {
  const data = await walletBalances(wallets);
  if (data && data[0] && data[0].balances) {
    const balances = await balancesByType(data, 'available', true);
    const delegated = await balancesByType(data, 'delegated');
    const commission = await balancesByType(data, 'commission');
    const staking_reward = await balancesByType(data, 'staking_reward');
    return {
      balances: balances.filter((b) => !!b.value && b.value > 0),
      delegated: delegated.filter((b) => !!b.value && b.value > 0),
      commission: commission.filter((b) => !!b.value && b.value > 0),
      staking_reward: staking_reward.filter((b) => !!b.value && b.value > 0),
    };
  }
  return null;
  // const balances: ParsedBalance[] = [];
  // data.forEach((d) => {
  //   d.balances &&
  //     d.balances.available.forEach((b) => {
  //       if (b.prices.USD && b.prices.USD > 0)
  //         balances.push({
  //           chain: d.chain,
  //           wallet: d.wallet,
  //           token: b.asset,
  //           balance: b.balance / 10 ** b.asset.decimals,
  //           priceId: b.asset.symbol,
  //           prices: b.prices,
  //           value: (b.balance / 10 ** b.asset.decimals) * b.prices.USD,
  //         });
  //     });
  // });
  // const balances: ParsedBalance[] = data.map((d) => d.balances).flat();
  // const withPrice = balances.filter((b) => b?.balances?.available.);
  // const tokensToFetch = nonNativeTokens(balances);
  // const tokensDetails = await getTokensDetails(tokensToFetch);
  // const data = parseTokens(balances, tokensDetails);
  // const wBalance = flatten(data).filter((f) => f.balance > 0);
  // // TODO remove this and switch back to wBalance for everything below after Osmosis/Cosmos is resolved
  // const notNft = wBalance.filter(
  //   (f) => ['Osmosis', 'Cosmos'].includes(f.chain) || f.token?.decimals == 18
  // );
  // const pByChain = await getPriceByToken(notNft.filter((t) => t.priceId));
  // const pByContract = await getPriceByContract(notNft.filter((t) => !t.priceId));
  // const withImages = await getTokenImages(balances);
  // return withImages;
  // console.log(withImages);
  // return [...pByChain, ...withImages];
};
