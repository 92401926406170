export const ruleIds = [
  'equals',
  'not',
  'before',
  'after',
  'above',
  'below',
  'aboveE',
  'belowE',
] as const;
export const ruleTypes: RuleType[] = [
  { id: 'equals', name: 'Equals', a: ['Date', 'Wallet', 'Blockchain', 'Asset', 'Amount', 'Type'] },
  { id: 'not', name: 'Not Equals', a: ['Wallet', 'Blockchain', 'Asset', 'Amount', 'Type'] },
  { id: 'before', name: 'Before or Equal', a: ['Date'] },
  { id: 'after', name: 'After or Equal', a: ['Date'] },
  { id: 'above', name: 'Above', a: ['Amount'] },
  { id: 'below', name: 'Below', a: ['Amount'] },
  { id: 'aboveE', name: 'Above or Equal', a: ['Amount'] },
  { id: 'belowE', name: 'Below or Equal', a: ['Amount'] },
];
export const filterTypes = ['Date', 'Wallet', 'Blockchain', 'Asset', 'Amount', 'Type'] as const;
export type FilterType = typeof filterTypes[number];
export type RuleType = { id: typeof ruleIds[number]; name: string; a: FilterType[] };
export type ActiveFilter = { id: number; type: FilterType; rule: RuleType; value: string | number };
export type InputType = 'text' | 'number' | 'date' | 'chain' | 'type' | 'wallet';
