import { Fragment, ReactElement, ReactNode } from 'react';
import { Dialog } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../Redux';
import clsx from 'clsx';
import { selectModal, setModal } from '../../Redux/appReducer';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Button from '../button';

export type ModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'auto' | 'tiny';

export type Modal = {
  title?: string;
  content: () => ReactNode;
  onBack?: () => void;
  size?: ModalSize;
  disableClose?: boolean;
};

type SizeNames = 'large' | 'medium' | 'small' | 'mini';

const sizes: Record<SizeNames, string[]> = {
  large: ['max-w-screen-xl'],
  medium: ['max-w-screen-lg'],
  small: ['max-w-screen-md'],
  mini: ['max-w-screen-sm'],
};

const ModalContainer = () => {
  const dispatch = useDispatch();
  const modal = useAppSelector(selectModal);
  const sizeClassName = sizes[modal?.size || 'mini'];

  return (
    <Transition appear show={!!modal} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-50 h-full w-full overflow-y-auto overflow-x-hidden p-4 text-center sm:p-6 lg:p-8 xl:p-10 3xl:p-12 flex items-center justify-center'
        onClose={() => dispatch(setModal(null))}
      >
        {modal && (
          <>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 z-40 cursor-pointer bg-gray-700/60 backdrop-blur' />
            </Transition.Child>

            <div className='sr-only'>
              <Button
                size='small'
                color='gray'
                shape='circle'
                onClick={() => dispatch(setModal(null))}
                className='opacity-50 hover:opacity-80'
              >
                <XMarkIcon className='h-auto w-[13px]' />
              </Button>
            </div>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-105'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-105'
            >
              <div
                className={clsx(
                  'relative z-50 w-full text-left flex items-center justify-center',
                  sizeClassName
                )}
              >
                <div className='rounded-lg bg-light-dark overflow-hidden shadow w-full'>
                  {modal.title && (
                    <div className='bg-light-dark px-6 py-4 border-b border-gray-700'>
                      <div className='sm:flex sm:items-center sm:justify-between'>
                        <div className='text-lg font-medium'>{modal.title}</div>
                        <div className='mt-5 flex justify-center sm:mt-0'>
                          <XMarkIcon
                            className='h-auto w-4 cursor-pointer'
                            onClick={() => dispatch(setModal(null))}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='w-full'>
                    {modal.content()}
                    {/* <dl className='grid grid-cols-1 sm:grid-cols-3 border-t border-gray-800 bg-light-dark'>
                      <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6 border-r border-gray-800'>
                        <dt className='text-sm font-medium text-gray-400 truncate'>Claimable</dt>
                        <dd className='mt-1 text-xl font-semibold text-white'>$7,397</dd>
                      </div>

                      <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6 border-r border-gray-800'>
                        <dt className='text-sm font-medium text-gray-400 truncate'>Assets</dt>
                        <dd className='mt-1 text-xl font-semibold text-white'>$11,879.32</dd>
                      </div>

                      <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6'>
                        <dt className='text-sm font-medium text-gray-400 truncate'>Debts</dt>
                        <dd className='mt-1 text-xl font-semibold text-white'>$24.57</dd>
                      </div>
                    </dl> */}
                  </div>
                </div>
                {/* <div className='bg-light-dark'></div>
                {modal.content()} */}
              </div>
            </Transition.Child>
          </>
        )}
      </Dialog>
    </Transition>
  );
};

export default ModalContainer;
