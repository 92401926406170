import clsx from 'clsx';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

export type SelectItem = { label: string; value: string };

const Select = ({
  options,
  selected,
  setSelected,
}: {
  options: SelectItem[];
  selected: SelectItem;
  setSelected: React.Dispatch<React.SetStateAction<SelectItem>>;
}) => {
  return (
    <div className='relative w-full md:w-auto z-50'>
      <Listbox value={selected} onChange={setSelected}>
        <Listbox.Button className='flex h-11 w-full items-center justify-between rounded-lg px-4 text-sm bg-dark text-white md:w-36 lg:w-56 xl:w-56'>
          {selected.label}
          <ChevronDownIcon className='w-4 h-4' />
        </Listbox.Button>
        <Transition
          enter='ease-out duration-200'
          enterFrom='opacity-0 translate-y-2'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 -translate-y-0'
          leaveTo='opacity-0 translate-y-2'
        >
          <Listbox.Options className='absolute left-0 z-10 mt-2 w-full origin-top-right rounded-lg p-3 shadow-large drop-shadow-lg border max-h-52 overflow-y-auto border-gray-800 bg-dark'>
            {options.map((option, idx) => (
              <Listbox.Option key={idx} value={option}>
                {({ selected }) => (
                  <div
                    className={clsx(
                      `block cursor-pointer rounded-lg px-3 py-2 text-sm font-medium transition text-white`,
                      selected ? 'my-1 bg-gray-800' : 'hover:bg-gray-700'
                    )}
                  >
                    {option.label}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default Select;
