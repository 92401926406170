import { TransactionDisplay } from '../../../types/index';
import { ActiveFilter } from './Types';

const filterWalletEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const walletFilters = activeFilters
    .filter((f) => f.type === 'Wallet' && f.rule.id === 'equals')
    .map((f) => f.value.toString().toLowerCase());
  if (walletFilters.length === 0) return data;
  return data.filter((tr) => {
    return tr.transaction.wallets.some(
      (wallet) => wallet && walletFilters.includes(wallet.toLowerCase())
    );
  });
};

const filterWalletNotEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const walletFilters = activeFilters
    .filter((f) => f.type === 'Wallet' && f.rule.id === 'not')
    .map((f) => f.value.toString().toLowerCase());
  if (walletFilters.length === 0) return data;
  return data.filter((tr) => {
    return !tr.transaction.wallets.some(
      (wallet) => wallet && walletFilters.includes(wallet.toLowerCase())
    );
  });
};

const filterAssetEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const assetFilters = activeFilters
    .filter((f) => f.type === 'Asset' && f.rule.id === 'equals')
    .map((f) => f.value.toString().toLowerCase());
  if (assetFilters.length === 0) return data;
  return data.filter((tr) => {
    return tr.transaction.assets.some(
      (asset) => asset && assetFilters.includes(asset.toLowerCase())
    );
  });
};

const filterAssetNotEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const assetFilters = activeFilters
    .filter((f) => f.type === 'Asset' && f.rule.id === 'not')
    .map((f) => f.value.toString().toLowerCase());
  if (assetFilters.length === 0) return data;
  return data.filter((tr) => {
    return !tr.transaction.assets.some(
      (asset) => asset && assetFilters.includes(asset.toLowerCase())
    );
  });
};

const filterAmountEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const amountFilters = activeFilters.filter((f) => f.type === 'Amount' && f.rule.id === 'equals');
  if (amountFilters.length === 0) return data;
  return data.filter((tr) => {
    return amountFilters.some((filter) => {
      return Number(tr.transaction.amounts[0]) === Number(filter.value);
    });
  });
};

const filterAmountNotEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const amountFilters = activeFilters.filter((f) => f.type === 'Amount' && f.rule.id === 'not');
  if (amountFilters.length === 0) return data;
  return data.filter((tr) => {
    return !amountFilters.some((filter) => {
      return Number(tr.transaction.amounts[0]) === Number(filter.value);
    });
  });
};

const filterAmountGreaterThan = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const amountFilters = activeFilters.filter(
    (f) => f.type === 'Amount' && (f.rule.id === 'above' || f.rule.id === 'aboveE')
  );
  if (amountFilters.length === 0) return data;
  return data.filter((tr) => {
    return amountFilters.some((filter) => {
      return Number(tr.transaction.amounts[0]) > Number(filter.value);
    });
  });
};

const filterAmountLessThan = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const amountFilters = activeFilters.filter(
    (f) => f.type === 'Amount' && (f.rule.id === 'below' || f.rule.id === 'belowE')
  );
  if (amountFilters.length === 0) return data;
  return data.filter((tr) => {
    return amountFilters.some((filter) => {
      return Number(tr.transaction.amounts[0]) < Number(filter.value);
    });
  });
};

const filterDateEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const dateFilters = activeFilters.filter((f) => f.type === 'Date' && f.rule.id === 'equals');
  if (dateFilters.length === 0) return data;
  return data.filter((tr) => {
    return dateFilters.some((filter) => {
      return filter.value === tr.transaction.day;
    });
  });
};

const filterDateNotEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const dateFilters = activeFilters.filter((f) => f.type === 'Date' && f.rule.id === 'not');
  if (dateFilters.length === 0) return data;
  return data.filter((tr) => {
    return !dateFilters.some((filter) => {
      return filter.value === tr.transaction.day;
    });
  });
};

const filterDateGreaterThan = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const dateFilters = activeFilters.filter((f) => f.type === 'Date' && f.rule.id === 'after');
  if (dateFilters.length === 0) return data;
  return data.filter((tr) => {
    return dateFilters.some((filter) => {
      return tr.transaction.day > filter.value;
    });
  });
};

const filterDateLessThan = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const dateFilters = activeFilters.filter((f) => f.type === 'Date' && f.rule.id === 'before');
  if (dateFilters.length === 0) return data;
  return data.filter((tr) => {
    return dateFilters.some((filter) => {
      return tr.transaction.day < filter.value;
    });
  });
};

const filterByChainEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const chainFilters = activeFilters
    .filter((f) => f.type === 'Blockchain' && f.rule.id === 'equals')
    .map((f) => f.value.toString().toLowerCase());
  if (chainFilters.length === 0) return data;
  return data.filter((tr) => {
    return chainFilters.includes(tr.transaction.chain.toLowerCase());
  });
};

const filterByChainNotEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const chainFilters = activeFilters
    .filter((f) => f.type === 'Blockchain' && f.rule.id === 'not')
    .map((f) => f.value.toString().toLowerCase());
  if (chainFilters.length === 0) return data;
  return data.filter((tr) => {
    return !chainFilters.includes(tr.transaction.chain.toLowerCase());
  });
};

const filterByTypeEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const typeFilters = activeFilters
    .filter((f) => f.type === 'Type' && f.rule.id === 'equals')
    .map((f) => f.value.toString().toLowerCase());
  if (typeFilters.length === 0) return data;
  return data.filter((tr) => {
    return typeFilters.includes(tr.transaction.type.toLowerCase());
  });
};

const filterByTypeNotEqual = (data: TransactionDisplay[], activeFilters: ActiveFilter[]) => {
  const typeFilters = activeFilters
    .filter((f) => f.type === 'Type' && f.rule.id === 'not')
    .map((f) => f.value.toString().toLowerCase());
  if (typeFilters.length === 0) return data;
  return data.filter((tr) => {
    return !typeFilters.includes(tr.transaction.type.toLowerCase());
  });
};

export const filterTransactions = (
  tableData: TransactionDisplay[],
  activeFilters: ActiveFilter[]
) => {
  let data = tableData;
  data = filterWalletEqual(data, activeFilters);
  data = filterWalletNotEqual(data, activeFilters);
  data = filterAssetEqual(data, activeFilters);
  data = filterAssetNotEqual(data, activeFilters);
  data = filterAmountEqual(data, activeFilters);
  data = filterAmountNotEqual(data, activeFilters);
  data = filterAmountGreaterThan(data, activeFilters);
  data = filterAmountLessThan(data, activeFilters);
  data = filterDateEqual(data, activeFilters);
  data = filterDateNotEqual(data, activeFilters);
  data = filterDateGreaterThan(data, activeFilters);
  data = filterDateLessThan(data, activeFilters);
  data = filterByChainEqual(data, activeFilters);
  data = filterByChainNotEqual(data, activeFilters);
  data = filterByTypeEqual(data, activeFilters);
  data = filterByTypeNotEqual(data, activeFilters);
  return data;
};
