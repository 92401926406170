import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { BreakdownItem } from '../../../../types';
import shuffle from 'lodash/shuffle';

const BreakdownChart = ({ items }: { items: BreakdownItem[] }) => {
  const series: ApexAxisChartSeries | ApexNonAxisChartSeries = items.map((item) => item.value);
  const labels: string[] | undefined = items.map((item) => item.label);
  const options: ApexOptions = {
    chart: {
      animations: {
        speed: 400,
        animateGradually: {
          enabled: false,
        },
      },
      fontFamily: 'inherit',
      foreColor: 'inherit',
      height: '170px',
      type: 'donut',
      sparkline: {
        enabled: true,
      },
    },
    colors: shuffle([
      '#7267b9',
      '#aa62b7',
      '#d95ba6',
      '#fc5a8a',
      '#ff6967',
      '#ff853f',
      '#ffa600',
      '#40b964',
    ]),
    labels: labels,
    plotOptions: {
      pie: {
        customScale: 1,
        expandOnClick: false,
        donut: {
          size: '70%',
        },
      },
    },
    series: series,
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: 'dark',
      custom: ({
        seriesIndex,
        w,
      }): string => `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                      <div class="w-3 h-3 rounded-full" style="background-color: ${
                        w.config.colors[seriesIndex]
                      };"></div>
                      <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                      <div class="ml-2 text-md font-bold leading-none">${Number(
                        w.config.series[seriesIndex]
                      ).toLocaleString(undefined, { maximumFractionDigits: 2 })}%</div>
                    </div>`,
    },
  };
  return (
    <ReactApexChart
      className='flex flex-auto items-center justify-center w-full h-full'
      options={options}
      series={options.series}
      type='donut'
    />
  );
};

export default BreakdownChart;
