import { Currency, Options } from '../types';
import * as base58 from '../utils/externals/base58';
import * as segwit from '../utils/externals/segwit_addr';
import * as cryptoUtils from '../utils/utils';

const DEFAULT_NETWORK_TYPE = 'prod';

function getDecoded(address: string) {
  try {
    return base58.decode(address);
  } catch (e) {
    // if decoding fails, assume invalid address
    return null;
  }
}

function getChecksum(hashFunction: string, payload: string) {
  // Each currency may implement different hashing algorithm
  switch (hashFunction) {
    // blake then keccak hash chain
    case 'blake256keccak256':
      const blake = cryptoUtils.blake2b256(payload);
      return cryptoUtils.keccak256Checksum(Buffer.from(blake, 'hex'));
    case 'blake256':
      return cryptoUtils.blake256Checksum(payload);
    case 'keccak256':
      return cryptoUtils.keccak256Checksum(payload);
    case 'sha256':
    default:
      return cryptoUtils.sha256Checksum(payload);
  }
}

function getAddressType(address: string, currency: Currency): any {
  // should be 25 bytes per btc address spec and 26 decred
  const expectedLength = currency.expectedLength || 25;
  const hashFunction = currency.hashFunction || 'sha256';
  const decoded = getDecoded(address);

  if (decoded) {
    const length = decoded.length;

    if (length !== expectedLength) {
      return null;
    }

    const checksum = cryptoUtils.toHex(decoded.slice(length - 4, length)),
      body = cryptoUtils.toHex(decoded.slice(0, length - 4)),
      goodChecksum = getChecksum(hashFunction, body);

    return checksum === goodChecksum
      ? cryptoUtils.toHex(decoded.slice(0, expectedLength - 24))
      : null;
  }

  return null;
}

function isValidP2PKHandP2SHAddress(address: string, currency: Currency, opts: Options): boolean {
  const networkType = opts ? opts.networkType : DEFAULT_NETWORK_TYPE;

  let correctAddressTypes: string[];
  const addressType = getAddressType(address, currency);

  if (addressType) {
    if (networkType === 'prod' || networkType === 'testnet') {
      correctAddressTypes = currency.addressTypes![networkType];
    } else if (currency.addressTypes) {
      correctAddressTypes = currency.addressTypes.prod.concat(currency.addressTypes.testnet);
    } else {
      return false;
    }

    return correctAddressTypes.indexOf(addressType) >= 0;
  }

  return false;
}

export function isValidAddress(address: string, currency: Currency, opts: Options): boolean {
  return (
    isValidP2PKHandP2SHAddress(address, currency, opts) ||
    segwit.isValidAddress(address, currency, opts) ||
    /^(xpub|Ltub|Mtub|zpub)[a-zA-HJ-NP-Z0-9]{100,112}$/.test(address) ||
    /^(ltc1|L|M)[a-zA-HJ-NP-Z0-9]{25,130}$/.test(address)
  );
}
