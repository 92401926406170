import { Listbox, Transition } from '@headlessui/react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import { Table } from '@tanstack/react-table';
import clsx from 'clsx';
import { size } from 'lodash';
import { useState, useEffect } from 'react';
import { TransactionDisplay } from '../../types';
import Button from '../../UI/button/button';

export const TablePagination = ({ table }: { table: Table<TransactionDisplay> }) => {
  const [perPage, setPerPage] = useState(table.getState().pagination.pageSize);

  useEffect(() => {
    table.setPageSize(Number(perPage));
  }, [perPage]);

  return (
    <div className='flex justify-between items-center px-6 mt-3'>
      <div className='flex items-center justify-center rounded-lg text-sm shadow-card bg-light-dark lg:py-6'>
        <div className='flex items-center gap-5'>
          <Button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            title='Previous'
            shape='page'
            variant='page'
            color='page'
            size='square'
            className='text-white disabled:text-gray-400'
          >
            <ChevronDoubleLeftIcon className='h-auto w-4' />
          </Button>
          <Button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            title='Previous'
            shape='page'
            variant='page'
            color='page'
            size='square'
            className='text-white disabled:text-gray-400'
          >
            <ChevronLeftIcon className='h-auto w-4' />
          </Button>
          <div className='flex flex-col items-center justify-center w-20 sm:w-auto'>
            <div>Page</div>
            <strong className='font-semibold'>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </div>
          <Button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            title='Next'
            shape='page'
            variant='page'
            color='page'
            size='square'
            className='dark:text-white disabled:text-gray-400'
          >
            <ChevronRightIcon className='h-auto w-4' />
          </Button>
          <Button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            title='Next'
            shape='page'
            variant='page'
            color='page'
            size='square'
            className='dark:text-white disabled:text-gray-400'
          >
            <ChevronDoubleRightIcon className='h-auto w-4' />
          </Button>
        </div>
      </div>
      <div className='relative'>
        <Listbox value={perPage} onChange={setPerPage}>
          <Listbox.Button className='h-11 flex w-full items-center justify-between rounded-sm px-4 text-sm bg-dark text-white'>
            Show {perPage}
            <ChevronDownIcon className='ml-1 w-4 h-4' />
          </Listbox.Button>
          <Transition
            enter='ease-out duration-200'
            enterFrom='opacity-0 translate-y-2'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 -translate-y-0'
            leaveTo='opacity-0 translate-y-2'
          >
            <Listbox.Options className='absolute bottom-12 left-0 z-10 mt-2 w-full origin-top-right rounded-lg p-3 shadow-large bg-dark max-h-64 overflow-y-auto'>
              {[10, 20, 30, 40, 50].map((filter, idx) => (
                <Listbox.Option key={idx} value={filter}>
                  {({ selected }) => (
                    <div
                      className={clsx(
                        `block cursor-pointer rounded-lg px-3 py-2 text-sm font-medium transition text-white truncate`,
                        selected ? 'my-1 bg-gray-800' : 'hover:bg-gray-700'
                      )}
                    >
                      {filter}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </Listbox>
      </div>
      {/* <select
        value={table.getState().pagination.pageSize}
        onChange={(e) => {
          table.setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select> */}
    </div>
  );
};
