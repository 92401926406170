import { flatten } from 'lodash';
import { StoredWallet, TokenDetails, Transaction } from '../types';
import { blockpitApi, contentApi, taxApi } from './';
import { config } from '../config';

export type Chain = {
  id: ChainID;
  name: string;
  icon: string;
  symbol: string;
  token?: TokenDetails;
  coingecko?: string;
  platform?: string;
  explorerTx: string;
  explorerAcc: string;
};

export const chainIds = [
  'Avalanche',
  // 'BinanceChain',
  'BinanceSmartChain',
  // 'BitcoinCash',
  'Bitcoin',
  // 'Cardano',
  'Cosmos',
  'CryptoOrg',
  'Dogecoin',
  // 'EthereumClassic',
  'Ethereum',
  // 'EthereumPow',
  'Fantom',
  // 'Klaytn',
  'Litecoin',
  // 'Osmosis',
  'Polygon',
  'Ripple',
  'TerraClassic',
  'Terra',
  // 'Tron',
  // 'Velas',
] as const;

export type ChainID = typeof chainIds[number];

export type Response<T> = {
  status: boolean;
  message: string;
  height: number;
  version: string;
  result: T | null;
};

export type NFTCollection = {
  address: string;
  name: string;
  sample_image: string;
  symbol: string;
};

export type NFTSingleAsset = {
  address: string;
  identifier: string;
  symbol: string;
  name: string;
  image: string;
};
export const getWalletNfts = async (address: string, chain: ChainID) => {
  const res = await blockpitApi.get<Response<NFTCollection[]>>(
    `/${chain}/GetWalletNftCollections?address=${address}`,
    {}
  );

  if (!res.ok) return null;
  // if (!res.ok) throw new Error(res.status?.toString());

  return res.data;
};

export const getWalletNftsByCollection = async (
  address: string,
  chain: ChainID,
  contract: string
) => {
  const res = await blockpitApi.get<Response<NFTSingleAsset[]>>(
    `/${chain}/GetWalletNftsByCollections?address=${address}&collection_address=${contract}`,
    {}
  );

  if (!res.ok) return null;
  // if (!res.ok) throw new Error(res.status?.toString());

  return res.data;
};

export const validateWalletAll = async (address: string) => {
  return Promise.all(
    chainIds.map((chain) =>
      blockpitApi
        .get<Response<'true' | 'false'>>(`/${chain}/IsValidImportAccount?address=${address}`, {})
        .then((r) => ({ chain, valid: r?.ok && r?.data?.result === 'true' }))
        .catch(() => ({ chain, valid: false }))
    )
  );
};

export const validateWallet = async (address: string, chain: ChainID) => {
  const res = await blockpitApi.get<Response<'true' | 'false'>>(
    `/${chain}/IsValidImportAccount?address=${address}`,
    {}
  );

  if (!res.ok) return null;
  // if (!res.ok) throw new Error(res.status?.toString());

  return res.data;
};

export const rejectDelay = (ms) =>
  new Promise((_, reject) => setTimeout(reject, ms, new Error('timeout')));

export type TransactionResponse = {
  chain: ChainID;
  transactions: Transaction[];
  wallet: string;
};

export type BalanceSet = Record<string, number | null>;
export type AvailableAsset = {
  asset: {
    asset_id: number;
    symbol: string;
    name: string;
    address: string;
    identifier: string | null;
    decimals: number;
  };
  balance: number;
  prices: {
    EUR: number;
    USD: number;
  };
};
export type BalanceType = 'available' | 'delegated' | 'staking_reward' | 'commission';
// export type BalancesResponse = {
//   available: AvailableAsset[];
//   delegated?: BalanceSet;
//   staking_reward?: BalanceSet;
// };
export type BalancesResponse = Record<BalanceType, AvailableAsset[]>;
export type WalletBalance = {
  chain: ChainID;
  wallet: string;
  balances: BalancesResponse;
};
export type Balance = Omit<WalletBalance, 'balances'> &
  TokenDetails & {
    price: number;
    value: number;
  };

export type ReportPayload = {
  trades: Transaction[];
  baseBasketId: number;
  taxCountryCode: string;
  taxYear: number;
};

export type ReportResponse = {
  requestCreated: string;
  requestId: string;
};

export type Report = ReportResponse & {
  status: 'ready' | 'pending' | 'error';
  country: string;
  year: number;
  currency: string;
  taxReportUrl?: string;
};

export const generateReport = async ({
  trades,
  baseBasketId,
  taxCountryCode,
  taxYear,
}: ReportPayload) => {
  return await taxApi.post<ReportResponse>(`/requestReportCalculation`, {
    baseBasketId,
    taxCountryCode,
    taxYear,
    trades,
    format: 'pdf',
  });
  // console.log(res);
  // if (!res.ok) {
  //   const error = !!(res.data as any)?.errorCode
  //     ? (res.data as any)?.errorCode
  //     : res.status?.toString();
  //   console.log('errorFromHere', error);
  //   throw new Error(error);
  // }

  // return res.data;
};

type Content = {
  id: number;
  date: string;
  date_gmt: string;
  guid: { rendered: string };
  modified: string;
  modified_gmt: string;
  slug: string;
  status: string;
  type: string;
  link: string;
  title: { rendered: string };
  content: { rendered: string };
};
export const getWordpressContent = async (pageId: number) => {
  const res = await contentApi.get<Content>(`/pages/${pageId}`, {});
  if (!res.ok) throw new Error(res.status?.toString());
  return res.data;
};

export type ReportStatus = {
  requestId: string;
  status: string;
  format: string;
  data: any;
  errors: any[];
  warnings: any[];
  calculated: string;
  taxReportUrl?: string;
};

export const getReportStatus = async (reportId: string) => {
  const request_id = `${reportId}`;
  const res = await taxApi.get<ReportStatus>(`/checkResult`, { request_id });
  if (!res.ok) throw new Error(res.status?.toString());

  return res.data;
};
export const walletTransactions = async (wallets: StoredWallet[], isTax?: boolean) => {
  let params = {};
  if (isTax) {
    params = {
      outputFormat: 'TaxApi',
      taxIdStartIndex: 1,
    };
  }
  const res = await Promise.allSettled(
    flatten(
      wallets
        .filter((a) => a.validFor.length)
        .map((wallet) =>
          wallet.validFor.map((chain) =>
            blockpitApi
              .get<Response<Transaction[]>>(
                `/${chain}/GetTransactions?address=${wallet.address}`,
                params ?? {}
              )
              .then((r) => ({
                chain,
                transactions: r?.data?.result?.map((t) => ({ ...t, chain })),
                wallet: wallet.address,
              }))
              .catch(() => ({ chain, transactions: false }))
          )
        )
    ).map((p) => Promise.race([p, rejectDelay(config.timeout)]))
  );

  return res
    .filter((r) => r.status === 'fulfilled' && (r as any).value.transactions?.length)
    .map((r: any) => r.value) as TransactionResponse[];
};
