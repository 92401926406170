import { create } from 'apisauce';
import { store } from '../Redux';
// import { setSignature } from '../Redux/walletSlice';

export type ErrorResponse = {
  error: string;
};

const blockpitApi = create({
  // baseURL: 'https://financial-api.blockpit.io/api/',
  baseURL: 'https://financial-api.blockpit.io/api/v2',
  // baseURL: 'https://cors-anywhere.herokuapp.com/http://116.202.100.54/api/',
  // baseURL: process.env.REACT_APP_FULL_GAME_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiIwMl9iYW5hbmFib2FyZCIsInBheW1lbnR1c2VyaWQiOiIwMl9iYW5hbmFib2FyZCIsImNyZWF0ZXRpbWVzdGFtcG1zIjoiMTY3MDU4NDQwNjk5NyIsImV4cCI6MTcwMjMzNTYwMCwiaXNzIjoiQmxvY2twaXRLeXQiLCJhdWQiOiJCbG9ja3BpdEt5dCJ9.6GHa3q9qZTzDZUfEdsT4OLzszy8Xn8DtVA-3TfMHicg',
    // TODO remove this
    // 'x-api-key': 'demo-api-key',
  },
  // params: {
  //   apiKey: '2pTDE74oR0zw7h32xZuKf2Rm7taTIl',
  // },
});
const taxApi = create({
  baseURL: 'https://api.cryptotax.io/api/v2',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization:
      'Bearer eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJjcnlwdG90YXguaW8iLCJzdWIiOiI2MCJ9.nbjds7pYTqM9CP8HSw2232JpFpVwgHm6hSB3-ohyBjSjmQWGb33NL-81P3RCXMekoJgihHVwTrcZ8alKOQN2rQ',
  },
});

const contentApi = create({
  baseURL: 'https://blockpit.io/wp-json/wp/v2',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const reservoirApi = create({
  baseURL: 'https://api.reservoir.tools/',
  // baseURL: process.env.REACT_APP_FULL_GAME_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  // params: {
  //   apiKey: '2pTDE74oR0zw7h32xZuKf2Rm7taTIl',
  // },
});

const coinGeckoApi = create({
  baseURL: 'https://api.coingecko.com/api/v3/',
  // baseURL: process.env.REACT_APP_FULL_GAME_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  // params: {
  //   apiKey: '2pTDE74oR0zw7h32xZuKf2Rm7taTIl',
  // },
});

const osmosisApi = create({
  baseURL: 'https://api-osmosis.imperator.co/',
  // baseURL: process.env.REACT_APP_FULL_GAME_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  // params: {
  //   apiKey: '2pTDE74oR0zw7h32xZuKf2Rm7taTIl',
  // },
});

// blockpitApi.addResponseTransform((res) => {
//   if (!res.ok) {
//     if (res.status === 401) {
//       // store.dispatch(setSignature(''));
//     }
//     // store.dispatch(reset());
//   }
// });

// const getAuthHeaders = (token: string) => ({ headers: { 'web3-token': token } });

export { blockpitApi, reservoirApi, coinGeckoApi, osmosisApi, taxApi, contentApi };
