export function isValidAddress(address: string): boolean {
  if (
    /^(cro1)[a-zA-Z0-9]{38}$/.test(address) ||
    /^(crocncl1)[a-zA-Z0-9]{38}$/.test(address) ||
    /^(crocnclcons1)[a-zA-Z0-9]{38}$/.test(address)
  ) {
    // Check if it has the basic requirements of an address
    return true;
  }

  // Otherwise
  return false;
}
