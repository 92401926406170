import { format, getYear, parseISO, getUnixTime } from 'date-fns';
import { useState, useEffect } from 'react';
import Select, { SelectItem } from './Select';
import { reverse, flatten, orderBy } from 'lodash';
import { useAppSelector } from '../../Redux';
import {
  selectAllWallets,
  selectReports,
  addReport,
  removeReport,
  updateReport,
} from '../../Redux/walletReducer';
import {
  generateReport,
  getReportStatus,
  Report,
  ReportPayload,
  ReportResponse,
  TransactionResponse,
  walletTransactions,
} from '../../Api/blockpit';
import Button from '../../UI/button/button';
import { Transaction } from '../../types/index';
import Loader from '../../UI/loader';
import { useDispatch } from 'react-redux';
import { LoadingSpinner } from '../../UI/LoadingSpinner';
import { truncateOver } from '../History/index';
import { TrashIcon } from '@heroicons/react/24/solid';

const currYear = getYear(new Date());
const taxYears = Array.from({ length: currYear - 2013 + 1 }, (_, i) => ({
  label: `${currYear - i}`,
  value: `${currYear - i}`,
}));

const countries: SelectItem[] = [
  { label: 'Austria', value: 'AT' },
  { label: 'Germany', value: 'DE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Spain', value: 'ES' },
  { label: 'France', value: 'FR' },
  { label: 'United States', value: 'US' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'International', value: 'INT' },
];

const currencies = {
  USD: { label: 'USD', value: '227' },
  EUR: { label: 'EUR', value: '203' },
  CHF: { label: 'CHF', value: '231' },
};
//   { label: 'USD', value: '227' },
//   { label: 'EUR', value: '203' },
//   { label: 'CHF', value: '231' },
// ];

const getCurrencies = (country: string) => {
  switch (country) {
    case 'AT':
    case 'DE':
    case 'NL':
    case 'ES':
    case 'FR':
      return [currencies.EUR];
    case 'CH':
      return [currencies.CHF];
    case 'US':
      return [currencies.USD];
    case 'INT':
      return [currencies.EUR, currencies.USD, currencies.CHF];
    default:
      return [currencies.EUR];
  }
};

type TaxTransaction = {
  transactionId: number;
  tradeDate: string;
  exchangeOrderId: string;
  exchangeName: string;
  accountName: string;
  buyAssetId: string;
  buyAmount: string;
  sellAssetId: string;
  sellAmount: string;
  feeAssetId: string;
  feeAmount: string;
  type: string;
  chain: string;
};
export const TaxReport = () => {
  const [selectedCountry, setSelectedCountry] = useState<SelectItem>(countries[0]);
  const [selectedYear, setSelectedYear] = useState<SelectItem>(taxYears[0]);
  const [currencies, setCurrencies] = useState<SelectItem[]>(getCurrencies(selectedCountry.value));
  const [selectedCurrency, setSelectedCurrency] = useState<SelectItem>(currencies[0]);
  const [txData, setTxData] = useState<TaxTransaction[]>([]);
  const [existing, setExisting] = useState<Report | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const wallets = useAppSelector(selectAllWallets);
  const reports = useAppSelector(selectReports);
  const dispatch = useDispatch();

  useEffect(() => {
    const available = getCurrencies(selectedCountry.value);
    setCurrencies(available);
    setSelectedCurrency(available[0]);
  }, [selectedCountry]);

  const getTrades = async () => {
    if (txData.length) return txData;
    try {
      const data = await walletTransactions(wallets, true);
      if (data.length) {
        const allTxs = orderBy(
          flatten(data.map((d) => d.transactions)),
          (d: TaxTransaction) => getUnixTime(parseISO(d.tradeDate)),
          'asc'
        ).map((d: any, transactionId) => ({ ...d, transactionId }));
        setTxData(allTxs as any[]);
        return allTxs as any;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const generate = async () => {
    setError(null);
    setStatus('Fetching transactions');
    try {
      const trades = await getTrades();
      if (!trades) return setError('Unable to fetch transactions');
      setStatus('Requesting report');
      const payload: ReportPayload = {
        trades,
        taxCountryCode: selectedCountry.value,
        taxYear: Number(selectedYear.value),
        baseBasketId: Number(selectedCurrency.value),
      };
      const reportReq = await generateReport(payload);
      if (reportReq.ok && reportReq.data) {
        const report: Report = {
          status: 'pending',
          country: selectedCountry.label,
          year: Number(selectedYear.value),
          currency: selectedCurrency.label,
          ...reportReq.data,
        };
        dispatch(addReport(report));
        setStatus(null);
        queryStatus(report);
        setExisting(report);
      } else {
        console.log('error');
        setStatus(null);
        setError((reportReq.data as any)?.errorCode ?? 'Unable to generate report');
      }
    } catch (error) {
      setStatus(null);
      setError(JSON.stringify(error));
    }
  };

  const queryStatus = async (report: Report) => {
    dispatch(updateReport({ ...report, status: 'pending' }));
    try {
      const reportRes = await getReportStatus(report.requestId);
      if (reportRes) {
        if (reportRes.status == 'PROCESSING') return;
        console.log('status is', reportRes.status === 'COMPLETED' ? 'ready' : 'error');
        const isError = reportRes.errors?.[0]?.type && `error-${reportRes.errors[0].type}`;
        dispatch(
          updateReport({
            ...report,
            ...reportRes,
            status: reportRes.status === 'COMPLETED' ? 'ready' : isError ?? 'error-Failed',
            taxReportUrl: reportRes?.taxReportUrl,
          })
        );
        setExisting(null);
        console.log(reportRes);
      }
    } catch (error) {
      console.log('got error', error);
      dispatch(updateReport({ ...report, status: 'error' }));
      setExisting(null);
    }
  };

  useEffect(() => {
    if (!!existing && existing.status == 'pending') {
      const interval = setInterval(async () => {
        await queryStatus(existing);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [existing]);

  return (
    <div className='rounded-lg bg-light-dark shadow min-h-[250px]'>
      <div className='bg-light-dark p-6 flex flex-col gap-3'>
        <div className='sm:flex sm:items-center sm:justify-between border-b border-gray-700 pb-3'>
          <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
            <p className='text-base font-medium text-gray-200'>
              Create your tax report with country-specific reports for 7 countries (limited to first
              50 tx in a tax year). For full tax report with unlimited transactions and with
              centralized exchanges imports go to{' '}
              <a
                href='https://blockpit.io/en/cryptotaxes/?referral=bananaboard'
                target='_blank'
                rel='noreferrer noopener'
                className='underline'
              >
                Blockpit{' '}
              </a>
            </p>
          </div>
        </div>
        <div>
          <div className='flex items-center space-x-2 text-sm text-white/80'>
            <div className='flex flex-col gap-1'>
              Country
              <Select
                options={countries}
                selected={selectedCountry}
                setSelected={setSelectedCountry}
              />
            </div>
            <div className='flex flex-col gap-1'>
              Year
              <Select options={taxYears} selected={selectedYear} setSelected={setSelectedYear} />
            </div>
            <div className='flex flex-col gap-1'>
              Currency
              <Select
                options={currencies}
                selected={selectedCurrency}
                setSelected={setSelectedCurrency}
              />
            </div>
          </div>
          <div className='mt-3'>
            <Button size='mini' onClick={generate} disabled={!wallets.length}>
              Generate
            </Button>
          </div>
        </div>
        <div className='flex mt-2 items-center gap-2 text-sm text-white/70'>
          {status && (
            <>
              {status} <Loader variant='blink' size='small' className='mt-0.5 ml-0.5' />
            </>
          )}
          {error && <div className='flex text-red'>{error}</div>}
          {/* <div className='flex mt-2 items-center gap-2 text-sm text-white/70'>
            {reports?.length && reports.map((report) => <>{report.requestId}</>)}
          </div> */}
        </div>
        {!!reports && (
          <div>
            <div className='space-y-8 md:space-y-10 xl:space-y-12'>
              <div className='grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4'>
                {reports?.map((report, idx) => (
                  <div
                    key={report.requestId}
                    className='relative flex flex-col justify-between text-sm font-medium bg-dark border-gray-700/50 border p-3 pt-4 rounded-lg'
                  >
                    <div className='ml-2' title={report.requestId}>
                      {/* {truncateOver(report.requestId, 18)} */}
                      {report.country} | {report.year} | {report.currency}
                    </div>
                    <div className='ml-2 text-xs font-normal text-gray-300'>
                      Requested {format(parseISO(report.requestCreated), 'yyyy-MM-dd')}
                    </div>
                    <div className='flex flex-col justify-between h-full tracking-normal pl-2 mt-1'>
                      <div className='border-gray-700 flex items-center gap-1 text-xs'>
                        {report.status === 'pending' ? (
                          <LoadingSpinner className='scale-75' />
                        ) : report.status.startsWith('error') ? (
                          <div className='text-red'>{report.status.split('error-')[1]}</div>
                        ) : report.taxReportUrl ? (
                          <a href={report.taxReportUrl} target='_blank' rel='noreferrer'>
                            Download
                          </a>
                        ) : (
                          'Ready'
                        )}
                      </div>
                    </div>
                    <div className='absolute right-6'>
                      <Button
                        size='icon'
                        shape='pill'
                        className='text-sm flex items-center justify-center rounded-sm border transition-all hover:border-gray-500 hover:text-gray-300 border-gray-700 text-gray-400'
                        onClick={() => dispatch(removeReport(report.requestId))}
                      >
                        <TrashIcon className='h-3 w-3' />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
