const Skeleton = ({ message }: { message: string }) => {
  return (
    <div className='rounded-lg bg-light-dark overflow-hidden shadow min-h-[250px] flex items-center'>
      <div className='bg-light-dark p-6 h-full w-full'>
        <div className='flex items-center justify-center w-full h-full'>
          <div className='flex space-x-5 -mt-5'>
            <p className='text-xl font-bold text-gray-400'>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
