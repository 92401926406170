import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastMessage } from '.';
import { removeToastMessage } from '../../Redux/appReducer';

const SECONDS_TO_DISMISS = 2;

const Toast = ({ id, type, title, message, hideIcon }: ToastMessage) => {
  const dispatch = useDispatch();
  const removeToast = () => dispatch(removeToastMessage(id));

  useEffect(() => {
    const interval = setInterval(() => removeToast(), SECONDS_TO_DISMISS * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div
        aria-live='assertive'
        className={clsx(`inset-0 flex items-end pointer-events-none sm:p-6 sm:items-start`)}
      >
        <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          {/*  // TODO fix transition */}
          <Transition
            show={!!message}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='max-w-sm w-full bg-dark shadow-lg rounded-lg pointer-events-auto ring-2 ring-white ring-opacity-5 overflow-hidden'>
              <div className='p-4'>
                <div className='flex items-start'>
                  <div className='shrink-0 mt-[1px]'>
                    {type === 'error' ? (
                      <ExclamationTriangleIcon
                        className='h-5 w-5 text-orange-400'
                        aria-hidden='true'
                      />
                    ) : (
                      <CheckCircleIcon className='h-5 w-5 text-green-400' aria-hidden='true' />
                    )}
                  </div>
                  <div className='ml-3 w-0 flex-1'>
                    <p className='text-sm font-medium text-white'>{title}</p>
                    <p className='mt-1 text-sm text-gray-300'>{message}</p>
                  </div>
                  <div className='ml-4 shrink-0 flex'>
                    <button
                      className='bg-light-dark rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      onClick={removeToast}
                    >
                      <span className='sr-only'>Close</span>
                      <XMarkIcon className='h-5 w-5' aria-hidden='true' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Toast;
