import { ActiveFilter, FilterType, filterTypes, InputType, RuleType, ruleTypes } from './Types';
import { useState, useEffect } from 'react';
import { filterTransactions } from './Filters';
import { TransactionDisplay } from '../../../types';
import { SelectDropdown, FilterInput } from './FilterInputs';
import { CheckIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import Button from '../../../UI/button/button';

const FilterRow = ({
  id,
  onChange,
  onDelete,
}: {
  id: number;
  onChange: (data: ActiveFilter) => void;
  onDelete: () => void;
}) => {
  const [selectedFilterType, setSelectedFilterType] = useState<FilterType>('Date');
  const [selectedRuleType, setSelectedRuleType] = useState<RuleType>(ruleTypes[0]);
  const [filterValue, setFilterValue] = useState<string | number>('');
  const [activeFilter, setActiveFilter] = useState<ActiveFilter | null>(null);
  const [inputType, setInputType] = useState<InputType>('text');

  useEffect(() => {
    setSelectedRuleType(ruleTypes[0]);
  }, [selectedFilterType]);

  useEffect(() => {
    if (activeFilter) onChange(activeFilter);
  }, [activeFilter]);

  useEffect(() => {
    switch (selectedFilterType) {
      case 'Date':
        setInputType('date');
        break;
      case 'Amount':
        setInputType('number');
        break;
      case 'Type':
        setInputType('type');
        break;
      case 'Blockchain':
        setInputType('chain');
        break;
      case 'Wallet':
        setInputType('wallet');
        break;
      default:
        setInputType('text');
        break;
    }
  }, [selectedFilterType]);

  return (
    <div className='flex flex-col sm:flex-row items-center justify-between mt-3 gap-2.5 w-full bg-gray-700 p-1 sm:p-0 sm:bg-transparent rounded-sm sm:rounded-none'>
      <div className='flex items-center gap-2.5 flex-1 w-full'>
        <SelectDropdown
          options={filterTypes.slice()}
          selected={selectedFilterType}
          setSelected={(selected) => {
            setSelectedFilterType(selected as FilterType);
            setFilterValue('');
          }}
          width='md'
        />
        <SelectDropdown
          options={ruleTypes
            .filter((rule) => rule.a.includes(selectedFilterType))
            .map((rule) => rule.name)}
          selected={selectedRuleType.name}
          setSelected={(selected) =>
            setSelectedRuleType(ruleTypes.find((rule) => rule.name === selected) || ruleTypes[0])
          }
          width='md'
        />
      </div>
      <div className='flex items-center gap-2.5 w-full'>
        <div className='flex-1'>
          <FilterInput
            type={inputType}
            value={filterValue}
            onChange={(e) => {
              setFilterValue(e.target.value);
              setActiveFilter({
                id,
                type: selectedFilterType,
                rule: selectedRuleType,
                value: e.target.value,
              });
            }}
          />
        </div>
        <Button
          size='square'
          variant='square'
          onClick={() =>
            setActiveFilter({
              id,
              type: selectedFilterType,
              rule: selectedRuleType,
              value: filterValue,
            })
          }
        >
          <CheckIcon className='w-4 h-4' />
        </Button>
        <TrashIcon className='w-4 h-4 opacity-70 cursor-pointer' onClick={onDelete} />
      </div>
    </div>
  );
};

export const Filter = ({
  tableData,
  onFilter,
}: {
  tableData: TransactionDisplay[];
  onFilter: (data: TransactionDisplay[]) => void;
}) => {
  const [matchType, setMatchType] = useState<'All' | 'Some'>('All');
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([]);
  const [filterRows, setFilterRows] = useState<number[]>([]);

  const updateFilters = (data: ActiveFilter) => {
    setActiveFilters((filters) => [data, ...filters.filter((filter) => filter.id !== data.id)]);
  };

  useEffect(() => {
    if (!activeFilters.length) {
      onFilter(tableData);
    } else {
      const data = filterTransactions(tableData, activeFilters);
      onFilter(data);
    }
  }, [activeFilters]);

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='flex justify-between w-full'>
        <div className='gap-2 flex items-center justify-center text-xs sm:text-sm'>
          {!!filterRows.length && (
            <>
              {' '}
              <span>Match</span>
              <SelectDropdown
                size='sm'
                width='sm'
                options={['All', 'Some']}
                selected={matchType}
                setSelected={setMatchType}
              />{' '}
              Rules
            </>
          )}
        </div>
        <div className='gap-2 flex items-center justify-center'>
          {!!filterRows.length && (
            <Button size='mini' onClick={() => (setActiveFilters([]), setFilterRows([]))}>
              <span className='hidden sm:block'>Clear All</span>
              <span className='block sm:hidden'>
                <TrashIcon className='w-4 h-4' />{' '}
              </span>
            </Button>
          )}
          <Button
            size='mini'
            color='info'
            onClick={() => setFilterRows((rows) => [...rows, rows.length + 1])}
          >
            <span className='hidden sm:block'>Add Filter</span>
            <span className='block sm:hidden'>
              <PlusIcon className='w-4 h-4' />{' '}
            </span>
          </Button>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center w-full'>
        {filterRows.map((row, idx) => (
          <FilterRow
            key={idx}
            id={row}
            onChange={updateFilters}
            onDelete={() => {
              setFilterRows((rows) => rows.filter((r) => r !== row));
              setActiveFilters((filters) => filters.filter((filter) => filter.id !== row));
            }}
          />
        ))}
      </div>
    </div>
  );
};
