import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store, persistor } from './Redux';
import { PersistGate } from 'redux-persist/integration/react';
import { WagmiConfig, createClient } from 'wagmi';
import { getDefaultProvider } from 'ethers';
import { createBrowserRouter, RouterProvider, Route, Navigate } from 'react-router-dom';
import ErrorPage from './Pages/Error';
import Dashboard from './Pages/Dashboard';
import History from './Pages/History';
import Wallets from './Pages/Wallets';
import ModalContainer from './UI/modal';
import NFTGallery from './Pages/NFT';
import { ToastsContainer } from './UI/toast';
import { TaxReport } from './Pages/TaxReport';
import { Developers } from './Pages/Developers';
import { About } from './Pages/About';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '*',
        element: <Navigate to='/dashboard' replace />,
      },
      {
        path: '/',
        element: <Navigate to='/dashboard' replace />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'history',
        element: <History />,
      },
      {
        path: 'wallets',
        element: <Wallets />,
      },
      {
        path: 'nft',
        element: <NFTGallery />,
      },
      {
        path: 'report',
        element: <TaxReport />,
      },
      {
        path: 'developers',
        element: <Developers />,
      },
      {
        path: 'about',
        element: <About />,
      },
    ],
  },
]);
const client = createClient({
  autoConnect: true,
  provider: getDefaultProvider(),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <WagmiConfig client={client}>
        {/* REMOVED BECAUSE RENDERS TWICE */}
        {/* <React.StrictMode> */}
        <RouterProvider router={router} />
        {/* <App /> */}
        <ModalContainer />
        <ToastsContainer />
        {/* </React.StrictMode> */}
      </WagmiConfig>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
