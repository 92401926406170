import { Bars3CenterLeftIcon } from '@heroicons/react/24/solid';

export const Empty = ({ showFiltersMessage }: { showFiltersMessage?: boolean }) => (
  <div className='text-center flex flex-col items-center justify-center pt-4 pb-12 min-h-[40vh]'>
    <Bars3CenterLeftIcon className='w-10 h-10 bg-gray-700 p-2 rounded-sm mb-2' />
    <h3 className='mt-2 text-sm font-medium text-white'>No Transactions Found</h3>
    <p className='mt-1 text-sm text-gray-500'>
      Try a different wallet{!!showFiltersMessage && ' or set of filters'}.
    </p>
  </div>
);
