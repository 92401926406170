import { Chain, ChainID } from './Api/blockpit';

// const chainTokens: Record<ChainID, TokenDetails> = {
//   Avalanche: {
//     decimals: 18,
//     name: 'Wrapped AVAX',
//     symbol: 'WAVAX',
//     contractaddress: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
//     tokenType: 'TOKEN',
//     tokenId: '',
//     tokenURI: null,
//     structure: null,
//     details: null,
//   },
// };
export const chains: Chain[] = [
  {
    id: 'Avalanche',
    name: 'Avalanche',
    icon: '/images/tokens/avalanche.png',
    symbol: 'AVAX',
    coingecko: 'avalanche-2',
    platform: 'avalanche',
    explorerAcc: 'https://snowtrace.io/address',
    explorerTx: 'https://snowtrace.io/tx',
    token: {
      decimals: 18,
      name: 'AVAX',
      symbol: 'AVAX',
      contractaddress: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
      tokenType: 'TOKEN',
      tokenId: '',
      tokenURI: null,
      structure: null,
      details: null,
    },
  },
  // {
  //   id: 'BinanceChain',
  //   name: 'BinanceChain',
  //   icon: '/images/tokens/binance-coin.png',
  //   symbol: 'BSC',
  //   coingecko: 'binancecoin',
  //   platform: 'binancecoin',
  //   token: {
  //     decimals: 18,
  //     name: 'BNB',
  //     symbol: 'BNB',
  //     contractaddress: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
  //     tokenType: 'TOKEN',
  //     tokenId: '',
  //     tokenURI: null,
  //     structure: null,
  //     details: null,
  //   },
  // },
  {
    id: 'BinanceSmartChain',
    name: 'BinanceSmartChain',
    icon: '/images/tokens/binance-vnd.png',
    coingecko: 'binancecoin',
    platform: 'binance-smart-chain',
    symbol: 'BNB',
    explorerAcc: 'https://bscscan.com/address',
    explorerTx: 'https://bscscan.com/tx',
    token: {
      decimals: 18,
      name: 'BNB',
      symbol: 'BNB',
      contractaddress: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
      tokenType: 'TOKEN',
      tokenId: '',
      tokenURI: null,
      structure: null,
      details: null,
    },
  },
  // {
  //   id: 'BitcoinCash',
  //   name: 'BitcoinCash',
  //   icon: '/images/tokens/bitcoin-cash.png',
  //   symbol: 'BCH',
  //   coingecko: 'bitcoin-cash',
  //   platform: 'bitcoin-cash',
  //   explorerAcc: 'https://blockchair.com/bitcoin-cash/address',
  //   explorerTx: 'https://blockchair.com/bitcoin-cash/transaction',
  // },
  {
    id: 'Bitcoin',
    name: 'Bitcoin',
    icon: '/images/tokens/bitcoin.png',
    symbol: 'BTC',
    coingecko: 'bitcoin',
    platform: 'bitcoin',
    explorerAcc: 'https://blockchair.com/bitcoin/address',
    explorerTx: 'https://blockchair.com/bitcoin/transaction',
  },
  // {
  //   id: 'Cardano',
  //   name: 'Cardano',
  //   icon: '/images/tokens/cardano.png',
  //   symbol: 'ADA',
  //   coingecko: 'cardano',
  //   platform: 'cardano',
  // },
  {
    id: 'Cosmos',
    name: 'Cosmos',
    icon: '/images/tokens/cosmos.png',
    symbol: 'ATOM',
    coingecko: 'cosmos',
    platform: 'cosmos',
    explorerAcc: 'https://www.mintscan.io/cosmos/account',
    explorerTx: 'https://www.mintscan.io/cosmos/txs',
  },
  {
    id: 'CryptoOrg',
    name: 'CryptoOrg',
    icon: '/images/tokens/cryptorg.png',
    symbol: 'CTG',
    coingecko: 'cryptorg-token',
    platform: 'cryptorg-token',
    explorerAcc: 'https://crypto.org/explorer/account',
    explorerTx: 'https://crypto.org/explorer/tx',
  },
  {
    id: 'Dogecoin',
    name: 'Dogecoin',
    icon: '/images/tokens/dogecoin.png',
    symbol: 'DOGE',
    coingecko: 'dogecoin',
    platform: 'dogechain',
    explorerAcc: 'https://blockchair.com/dogecoin/address',
    explorerTx: 'https://blockchair.com/dogecoin/transaction',
  },
  // {
  //   id: 'EthereumClassic',
  //   name: 'EthereumClassic',
  //   icon: '/images/tokens/ethereum-classic.png',
  //   symbol: 'ETC',
  //   coingecko: 'ethereum-classic',
  //   platform: 'ethereum-classic',
  // },
  {
    id: 'Ethereum',
    name: 'Ethereum',
    icon: '/images/tokens/ethereum.png',
    coingecko: 'ethereum',
    platform: 'ethereum',
    symbol: 'ETH',
    explorerAcc: 'https://etherscan.com/address',
    explorerTx: 'https://etherscan.com/tx',
    token: {
      decimals: 18,
      name: 'ETH',
      symbol: 'ETH',
      contractaddress: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      tokenType: 'TOKEN',
      tokenId: '',
      tokenURI: null,
      structure: null,
      details: null,
    },
  },
  // {
  //   id: 'EthereumPow',
  //   name: 'EthereumPow',
  //   icon: '/images/tokens/ethereum-pow.png',
  //   symbol: 'ETHW',
  //   coingecko: 'ethereum-pow-iou',
  //   platform: 'ethereumpow',
  // },
  {
    id: 'Fantom',
    name: 'Fantom',
    icon: '/images/tokens/fantom.png',
    symbol: 'FTM',
    coingecko: 'fantom',
    platform: 'fantom',
    explorerAcc: 'https://ftmscan.com/account',
    explorerTx: 'https://ftmscan.com/tx',
  },
  // {
  //   id: 'Klaytn',
  //   name: 'Klaytn',
  //   icon: '/images/tokens/klaytn.png',
  //   symbol: 'KLAY',
  //   coingecko: 'klay-token',
  //   platform: 'klay-token',
  // },
  {
    id: 'Litecoin',
    name: 'Litecoin',
    icon: '/images/tokens/litecoin.png',
    symbol: 'LTC',
    coingecko: 'litecoin',
    explorerAcc: 'https://blockchair.com/litecoin/address',
    explorerTx: 'https://blockchair.com/litecoin/transaction',
  },
  // {
  //   id: 'Osmosis',
  //   name: 'Osmosis',
  //   icon: '/images/tokens/osmosis.png',
  //   symbol: 'OSMO',
  //   coingecko: 'osmosis',
  //   platform: 'osmosis',
  // },
  {
    id: 'Polygon',
    name: 'Polygon',
    icon: '/images/tokens/polygon.png',
    symbol: 'MATIC',
    coingecko: 'matic-network',
    platform: 'matic-network',
    explorerAcc: 'https://polygonscan.com/address',
    explorerTx: 'https://polygonscan.com/tx',
  },
  {
    id: 'Ripple',
    name: 'Ripple',
    icon: '/images/tokens/ripple.png',
    symbol: 'XRP',
    coingecko: 'ripple',
    platform: 'xrp',
    explorerAcc: 'https://xrpscan.com/account',
    explorerTx: 'https://xrpscan.com/tx',
  },
  {
    id: 'TerraClassic',
    name: 'TerraClassic',
    icon: '/images/tokens/terra-classic.png',
    symbol: 'LUNC',
    coingecko: 'terra-luna',
    explorerAcc: 'https://finder.terra.money/mainnet/account',
    explorerTx: 'https://finder.terra.money/mainnet/tx',
  },
  {
    id: 'Terra',
    name: 'Terra',
    icon: '/images/tokens/terra.png',
    symbol: 'LUNA',
    coingecko: 'luna-wormhole',
    platform: 'terra',
    explorerAcc: 'https://finder.terra.money/mainnet/account',
    explorerTx: 'https://finder.terra.money/mainnet/tx',
  },
  // {
  //   id: 'Tron',
  //   name: 'Tron',
  //   icon: '/images/tokens/tron.png',
  //   symbol: 'TRX',
  //   coingecko: 'tron',
  //   platform: 'tron',
  // },
  // {
  //   id: 'Velas',
  //   name: 'Velas',
  //   icon: '/images/tokens/velas.png',
  //   symbol: 'VLSX',
  //   coingecko: 'velas',
  //   platform: 'velas',
  // },
];
