import { NFTCollectionProps } from '../../types/index';

type Props = {
  collection: NFTCollectionProps;
  onClick?: () => void;
};
const NFTCollection = ({ collection, onClick }: Props) => {
  const { name, contract, coverImg, slug, owned } = collection;
  return (
    <div
      className='group relative overflow-hidden rounded-lg transition-transform hover:-translate-y-1 shadow-lg cursor-pointer'
      onClick={onClick}
    >
      <div className='relative flex aspect-[11/11] w-full justify-center overflow-hidden rounded-lg'>
        <img
          src={coverImg !== '' ? coverImg : '/images/nft-placeholder.png'}
          alt={name}
          style={{ objectFit: 'cover', objectPosition: '0px center' }}
        />
      </div>
      <div className='absolute top-0 left-0 z-[5] flex h-full w-full flex-col justify-between bg-gradient-to-t from-black p-5 md:p-6'>
        <a
          // [routerLink]="['/collection', slug]"
          className='absolute top-0 left-0 z-10 h-full w-full'
        ></a>
        <div className='flex justify-end gap-3'>
          <div className='z-10 ml-2 inline-flex shrink-0 items-center rounded-2xl bg-white/90 p-0.5 text-xs font-medium uppercase -tracking-wide text-white'>
            <a href={'https://opensea.io/collection/' + slug} target='_blank'>
              <img src='/images/icons/opensea.svg' className='w-4 h-4' />
            </a>
          </div>
          <div className='z-10 inline-flex shrink-0 items-center rounded-2xl bg-white/90 p-0.5 text-xs font-medium uppercase -tracking-wide text-white'>
            <a href={'https://looksrare.org/collections/' + contract} target='_blank'>
              <img src='/images/icons/looksrare.svg' className='w-4 h-4' />
            </a>
          </div>
          <div className='z-10 inline-flex shrink-0 items-center rounded-2xl bg-white/90 p-0.5 text-xs font-medium uppercase -tracking-wide text-white'>
            <a href={'https://x2y2.io/collection/' + contract} target='_blank'>
              <img src='/images/icons/x2y2.svg' className='w-4 h-4' />
            </a>
          </div>
        </div>
        <div className='block'>
          <h2 className='truncate text-lg font-medium -tracking-wider text-white'>{name}</h2>
          {/* <div className='text-sm font-medium -tracking-wide text-[#B6AAA2]'>{owned} Owned</div> */}
        </div>
      </div>
    </div>
  );
};

export default NFTCollection;
