import { DocumentDuplicateIcon, TrashIcon, WalletIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { ChainID } from '../../Api/blockpit';
import { chains } from '../../constants';
import { StoredWallet } from '../../types';
import Button from '../../UI/button';
import { truncateMiddle } from '../../Utils/truncateMiddle';

const Wallet = ({ wallet, onDelete }: { wallet: StoredWallet; onDelete: () => void }) => {
  const [copyButtonStatus, setCopyButtonStatus] = useState<string | null>();
  const [_, copyToClipboard] = useCopyToClipboard();
  const handleCopyToClipboard = () => {
    copyToClipboard(wallet.address);
    setCopyButtonStatus('Copied!');
    setTimeout(() => {
      setCopyButtonStatus(copyButtonStatus);
    }, 1000);
  };

  return (
    <div className='flex items-start justify-between text-sm font-medium bg-dark border-gray-700/50 border p-3 pt-4 rounded-lg'>
      <div className='ml-2'>
        <div className='-space-x-1 mb-2 flex'>
          <>
            {wallet.validFor
              .map((c) => chains.find((chain) => chain.id === c)?.icon)
              .map((icon, i) => (
                <img
                  key={i}
                  src={`${icon}`}
                  className='h-4 w-4 shrink-0 bg-white rounded-full border object-contain'
                />
              ))}
          </>
        </div>
        {truncateMiddle(wallet.address, 20, '...')}
      </div>
      <div className='flex flex-col justify-between h-full tracking-normal pl-2'>
        <div className='border-gray-700 flex items-center gap-1'>
          <Button
            size='icon'
            variant='solid'
            shape='pill'
            className='text-sm flex items-center justify-center rounded-sm border transition-all hover:border-gray-500 hover:text-gray-300 border-gray-700 text-gray-400'
            onClick={handleCopyToClipboard}
          >
            <DocumentDuplicateIcon className='h-3 w-3' />
          </Button>
          <Button
            size='icon'
            shape='pill'
            className='text-sm flex items-center justify-center rounded-sm border transition-all hover:border-gray-500 hover:text-gray-300 border-gray-700 text-gray-400'
            onClick={onDelete}
          >
            <TrashIcon className='h-3 w-3' />
          </Button>
          {/* <button onClick={handleCopyToClipboard}>
            <span className='mt-2 block text-xs -tracking-widest text-gray-400'>
              {copyButtonStatus}
            </span>
          </button> */}
          {/* <button onClick={onDelete}>
            <span className='text-sm flex items-center justify-center rounded-sm border transition-all hover:border-gray-300 hover:text-gray-300 border-gray-700 text-gray-400'>
              <TrashIcon className='h-3 w-3' />
            </span>
          </button> */}
        </div>
        <div className='mt-2 block text-xs text-gray-400 w-full text-center'>
          {copyButtonStatus}
        </div>
      </div>
    </div>
  );
};

export default Wallet;
