import clsx from 'clsx';
import { Listbox, Popover, Transition } from '@headlessui/react';
import { CalendarDaysIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { StoredWallet, TransactionDisplay, transactionTypes } from '../../../types';
import { truncateMiddle } from '../../../Utils/truncateMiddle';
import { useState, useEffect, ChangeEventHandler } from 'react';
import Button from '../../../UI/button/button';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { format, isValid, parse, getUnixTime, startOfDay, parseISO } from 'date-fns';
import { usePopper } from 'react-popper';
import { chainIds } from '../../../Api/blockpit';
import { InputType } from './Types';
import { useAppSelector } from '../../../Redux/index';
import { selectAllWallets } from '../../../Redux/walletReducer';
import { truncateOver } from '../index';

export const SelectDropdown = ({
  size = 'md',
  width = 'lg',
  options,
  selected,
  truncate,
  setSelected,
}: {
  size?: 'sm' | 'md' | 'lg';
  width?: 'sm' | 'md' | 'lg';
  options: string[];
  selected: string;
  truncate?: boolean;
  setSelected: (selected) => void;
}) => {
  return (
    <div className='relative w-full md:w-auto'>
      <Listbox value={selected} onChange={setSelected}>
        <Listbox.Button
          className={clsx(
            'flex w-full items-center justify-between rounded-lg px-4 text-sm bg-dark text-white',
            size == 'md' ? 'h-11' : size == 'lg' ? 'h-12' : 'h-8',
            width == 'md'
              ? 'w-full flex-1 md:w-36 lg:w-40 xl:w-56'
              : width == 'lg'
              ? 'w-full'
              : 'md:w-20 lg:w-24'
          )}
        >
          {truncate ? truncateOver(selected, 45) : selected}
          <ChevronDownIcon className='w-4 h-4' />
        </Listbox.Button>
        <Transition
          enter='ease-out duration-200'
          enterFrom='opacity-0 translate-y-2'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 -translate-y-0'
          leaveTo='opacity-0 translate-y-2'
          className='z-50'
        >
          <Listbox.Options className='absolute left-0 z-10 mt-2 w-full origin-top-right rounded-lg p-3 shadow-large bg-dark max-h-64 overflow-y-auto'>
            {options.map((filter, idx) => (
              <Listbox.Option key={idx} value={filter}>
                {({ selected }) => (
                  <div
                    className={clsx(
                      `block cursor-pointer rounded-lg px-3 py-2 text-sm font-medium transition text-white truncate`,
                      selected ? 'my-1 bg-gray-800' : 'hover:bg-gray-700'
                    )}
                  >
                    {filter}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export const TextNumberInput = ({
  value,
  type,
  onChange,
  disabled,
}: {
  value: string | number;
  type: InputType;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}) => {
  return (
    <input
      disabled={disabled}
      pattern='[0-9]*'
      autoComplete='off'
      type={type === 'number' ? 'number' : 'text'}
      placeholder={type === 'number' ? 'Enter Amount' : 'Search Symbol'}
      value={value}
      onChange={onChange}
      className={clsx(
        'flex items-center justify-between rounded-lg bg-dark border border-transparent leading-5 ',
        'h-11 w-full px-4 text-white text-sm sm:text-sm',
        'focus:outline-none focus:border-transparent focus:placeholder:text-gray-400 focus:ring-0',
        { 'opacity-50': disabled }
      )}
      name='filterValue'
    />
  );
};

export const DateInput = ({
  onChange,
  disabled,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}) => {
  const [selected, setSelected] = useState<Date>();
  const [inputValue, setInputValue] = useState<string>('');
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.currentTarget.value);
    const date = parse(e.currentTarget.value, 'y-MM-dd', new Date());
    if (isValid(date)) {
      setSelected(date);
    } else {
      setSelected(undefined);
    }
  };

  const handleDaySelect = (date: Date) => {
    setInputValue(date ? format(date, 'y-MM-dd') : '');
    setSelected(isValid(date) ? date : undefined);
    onChange({ target: { value: getUnixTime(startOfDay(date)) } } as any);
  };

  return (
    <div className='relative'>
      <input
        autoComplete='off'
        type={'text'}
        placeholder={format(new Date(), 'y-MM-dd')}
        value={inputValue}
        onChange={handleInputChange}
        className={clsx(
          'flex items-center justify-between rounded-lg bg-dark border border-transparent leading-5 ',
          'h-11 w-full px-4 text-white text-sm sm:text-sm cursor-pointer',
          'focus:outline-none focus:border-transparent focus:placeholder:text-gray-400 focus:ring-0',
          { 'opacity-50': disabled }
        )}
        name='filterValue'
      />
      <Popover className='absolute top-1/2 right-3 -translate-y-1/2 flex items-center'>
        {({ open }) => (
          <>
            <Popover.Button ref={setReferenceElement as any}>
              {/* <input
                autoComplete='off'
                type={'text'}
                placeholder={format(new Date(), 'y-MM-dd')}
                value={inputValue}
                onChange={handleInputChange}
                className={clsx(
                  'flex items-center justify-between rounded-lg bg-dark border border-transparent leading-5 ',
                  'h-11 w-full px-4 text-white text-sm sm:text-sm cursor-pointer',
                  'focus:outline-none focus:border-transparent focus:placeholder:text-gray-400 focus:ring-0',
                  { 'opacity-50': disabled }
                )}
                name='filterValue'
              /> */}
              <CalendarDaysIcon className='w-6 h-6' />
              {/* <div className='absolute'>
              </div> */}
            </Popover.Button>

            <Transition
              show={open}
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <Popover.Panel
                static
                ref={setPopperElement as any}
                style={styles.popper}
                {...attributes.popper}
              >
                <DayPicker
                  initialFocus={open}
                  mode='single'
                  defaultMonth={selected}
                  selected={selected}
                  onSelect={handleDaySelect as any}
                  className='bg-dark p-4 rounded-lg shadow-large'
                />
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export const WalletsInput = ({
  value,
  onChange,
}: {
  value: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}) => {
  const wallets = useAppSelector(selectAllWallets);
  return (
    <SelectDropdown
      options={wallets.map((w) => w.address)}
      selected={value as string}
      setSelected={(selected) => onChange({ target: { value: selected } } as any)}
      truncate
    />
  );
};

export const FilterInput = ({
  value,
  type,
  onChange,
  disabled,
}: {
  value: string | number;
  type: InputType;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}) => {
  if (type === 'wallet') {
    return <WalletsInput value={value} onChange={onChange} />;
  }
  if (['chain', 'type'].includes(type))
    return (
      <SelectDropdown
        options={type == 'chain' ? chainIds.slice() : transactionTypes.slice()}
        selected={value as string}
        setSelected={(selected) => onChange({ target: { value: selected } } as any)}
      />
    );
  if (type === 'date') {
    return <DateInput onChange={onChange} disabled={disabled} />;
  }
  return <TextNumberInput value={value} type={type} onChange={onChange} disabled={disabled} />;
};
