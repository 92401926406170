import Button from '../../../../UI/button';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { PortfolioSummary } from '../../../../types/index';
import Loader from '../../../../UI/loader';
import clsx from 'clsx';

const Skeleton = () => {
  return (
    <>
      <div className='bg-light-dark p-6'>
        <div className='sm:flex sm:items-center sm:justify-between pb-2'>
          <div className='sm:flex sm:space-x-5'>
            <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
              <p className='text-sm font-medium text-gray-400'>Net Worth</p>
              <p className='text-xl font-bold text-white sm:text-2xl'>
                <Loader variant='blink' size='small' className='mt-4 ml-0.5' />
              </p>
            </div>
          </div>
          <div className='mt-5 flex justify-center sm:mt-0'>
            <Button size='mini' disabled>
              <div className='flex items-center gap-2'>
                <ArrowPathIcon className='h-3 w-3' />
                Update
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <dl className='grid grid-cols-1 sm:grid-cols-3 border-t border-gray-800 bg-light-dark'>
          <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6 border-r border-gray-800'>
            <dt className='text-sm font-medium text-gray-400 truncate pt-0.5 pb-2'>Claimable</dt>
            <Loader variant='blink' size='small' className='mt-4 ml-0.5' />
          </div>

          <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6 border-r border-gray-800'>
            <dt className='text-sm font-medium text-gray-400 truncate pt-0.5 pb-2'>Assets</dt>
            <Loader variant='blink' size='small' className='mt-4 ml-0.5' />
          </div>

          <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6'>
            <dt className='text-sm font-medium text-gray-400 truncate pt-0.5 pb-2'>Debts</dt>
            <Loader variant='blink' size='small' className='mt-4 ml-0.5' />
          </div>
        </dl>
      </div>
    </>
  );
};

const Summary = ({
  summary,
  loading,
  onUpdate,
}: {
  summary: PortfolioSummary | null;
  loading: boolean;
  onUpdate: () => void;
}) => {
  if (!summary || loading) return <Skeleton />;
  return (
    <>
      <div className='bg-light-dark -mt-3 sm:mt-0 p-6'>
        <div className='sm:flex sm:items-center sm:justify-between'>
          <div className='sm:flex sm:space-x-5'>
            <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
              <p className='text-sm font-medium text-gray-400'>Net Worth</p>
              <p className='text-xl font-bold text-white sm:text-2xl'>
                ${summary.netWorth.toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </p>
            </div>
          </div>
          <div className='mt-5 flex justify-center sm:mt-0'>
            <Button size='mini' onClick={onUpdate} disabled={loading}>
              <div className='flex items-center gap-2'>
                <ArrowPathIcon className={clsx('h-3 w-3', { updating: loading })} />
                Update
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <dl className='grid grid-cols-1 sm:grid-cols-3 border-t border-gray-800 bg-light-dark'>
          <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6 border-r border-gray-800'>
            <dt className='text-sm font-medium text-gray-400 truncate'>Available</dt>
            <dd className='mt-1 text-xl font-semibold text-white'>
              ${summary.available.toLocaleString(undefined, { maximumFractionDigits: 2 })}
            </dd>
          </div>

          <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6 border-r border-gray-800'>
            <dt className='text-sm font-medium text-gray-400 truncate'>Claimable</dt>
            <dd className='mt-1 text-xl font-semibold text-white'>
              ${summary.claimable.toLocaleString(undefined, { maximumFractionDigits: 2 })}
            </dd>
          </div>

          <div className='px-4 py-5 bg-light-dark shadow overflow-hidden sm:p-6'>
            <dt className='text-sm font-medium text-gray-400 truncate'>Delegated</dt>
            <dd className='mt-1 text-xl font-semibold text-white'>
              ${summary.delegated.toLocaleString(undefined, { maximumFractionDigits: 2 })}
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
};

export default Summary;
