import type { BreakdownItem as BreakdownItemType } from '../../../../types';

const BreakdownItem = ({ item }: { item: BreakdownItemType }) => {
  return (
    <div className='flex flex-row items-center justify-between'>
      <div className='flex flex-row items-center overflow-hidden'>
        <div className='relative flex items-center text-base pointer-events-none select-none mr-1'>
          <img
            className='h-4 w-4 shrink-0 bg-white rounded-full p-0.5 object-contain'
            src={
              item.icon ||
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN88R8AAtUB6S/lfiQAAAAASUVORK5CYII='
            }
          />
        </div>
        <p className='truncate text-base ml-0.5'>{item.label}</p>
      </div>
      <p className='inline'>
        <span className='text-base'>
          {item.value.toLocaleString(undefined, { maximumFractionDigits: 2 })}%
        </span>
      </p>
    </div>
  );
};

export default BreakdownItem;
