import { format, parseISO } from 'date-fns';
import { NFTSingleAsset } from '../../Api/blockpit';
import { NFTAssetResponse } from '../../types/index';

type Props = {
  asset: NFTSingleAsset;
  contract: string;
};

const NFTAsset = ({ asset, contract }: Props) => {
  const { address, identifier, symbol, name, image } = asset;
  // const assetPrice =
  //   parseInt(asset.ownership.onSaleCount) > 0 && asset.ownership.floorAsk.price.amount.native;

  return (
    <div className='relative overflow-hidden rounded-lg shadow-card transition-all duration-200 hover:shadow-large bg-dark'>
      <a className='relative flex aspect-[11/11] w-full justify-center overflow-hidden'>
        <img
          src={image && image !== '' ? image : '/images/nft-placeholder.png'}
          alt={name}
          style={{ objectFit: 'cover', objectPosition: '0px center' }}
        />
      </a>

      <div className='h-32 p-5'>
        <a href='/nft-details' className='font-medium text-white text-sm'>
          {name || '#' + identifier}
        </a>
        {/* <div className='mt-1.5 flex'>
          <a href='/' className='text-xs text-gray-400 inline-flex items-center'>
            Acquired on {format(parseISO(asset.ownership.acquiredAt), 'MMM d, yyyy')}
          </a>
        </div> */}
        {/* {assetPrice && (
          <div className='text-base font-medium text-white mt-4 flex items-center'>
            <img src='/images/tokens/ethereum.png' className='w-4 h-4 -ml-1' alt='' />
            {assetPrice}
          </div>
        )} */}
        <div className='absolute bottom-6 right-5 flex justify-end gap-3'>
          <div className='z-10 ml-2 inline-flex shrink-0 items-center rounded-2xl bg-white/90 p-0.5 text-xs font-medium uppercase -tracking-wide text-white'>
            <a
              href={'https://opensea.io/assets/ethereum/' + contract + '/' + identifier}
              target='_blank'
            >
              <img src='/images/icons/opensea.svg' className='w-4 h-4' />
            </a>
          </div>
          <div className='z-10 inline-flex shrink-0 items-center rounded-2xl bg-white/90 p-0.5 text-xs font-medium uppercase -tracking-wide text-white'>
            <a
              href={'https://looksrare.org/collections/' + contract + '/' + identifier}
              target='_blank'
            >
              <img src='/images/icons/looksrare.svg' className='w-4 h-4' />
            </a>
          </div>
          <div className='z-10 inline-flex shrink-0 items-center rounded-2xl bg-white/90 p-0.5 text-xs font-medium uppercase -tracking-wide text-white'>
            <a href={'https://x2y2.io/collection/' + contract + '/' + identifier} target='_blank'>
              <img src='/images/icons/x2y2.svg' className='w-4 h-4' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTAsset;
