import { useState, useEffect } from 'react';
import { TransactionDisplay } from '../../types/index';
import { truncateMiddle } from '../../Utils/truncateMiddle';
import Filter from './Select';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  Row,
} from '@tanstack/react-table';
import Button from '../../UI/button';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';
import { TablePagination } from './Pagination';
import { Empty } from './Empty';
import clsx from 'clsx';

const columnHelper = createColumnHelper<TransactionDisplay>();

const columns = [
  columnHelper.accessor('transaction', {
    id: 'transaction',
    // sortingFn: (rowA: Row<TransactionDisplay>, rowB: Row<TransactionDisplay>, columnId: string) => {
    //   return (rowA.getValue('transaction') as TransactionDisplay['transaction']).date.localeCompare(
    //     (rowB.getValue('transaction') as TransactionDisplay['transaction']).date
    //   );
    // },
    cell: (info) => (
      <div className='flex items-start justify-center text-xs sm:text-sm flex-col'>
        <div className='text-white mb-1'>{info.getValue().date}</div>
        <a
          href={info.getValue().link}
          target='_blank'
          className='flex items-center text-xs text-gray-400 hover:text-gray-300 cursor-pointer'
        >
          {/* <img src={`/images/tokens/ethereum.png`} className='h-3 w-3 -ml-1' /> */}
          {truncateMiddle(info.getValue().hash, 15, '...')}
        </a>
      </div>
    ),
    header: () => <span>Transaction</span>,
    // minSize: 100,
    // maxSize: 140,
    size: 149,
  }),
  columnHelper.accessor('details', {
    id: 'details',
    cell: (info) => <div className='text-left text-xs sm:text-sm'>{info.getValue()}</div>,
    header: () => <span>Details</span>,
    size: 360,
  }),
  columnHelper.accessor('fee', {
    id: 'fee',
    cell: (info) => (
      <div className='flex items-center justify-end text-xs'>
        <div className='text-white'>
          {typeof info.getValue().amount === 'number'
            ? info.getValue().amount.toLocaleString(undefined, { maximumFractionDigits: 5 })
            : info.getValue().amount}{' '}
          {info.getValue().token}
        </div>
      </div>
    ),
    header: () => <div className='ml-auto text-right'>Fee</div>,
    // minSize: 100,
    // maxSize: 140,
    size: 140,
  }),
];

const TransactionsTable1 = ({ tableData }: { tableData: TransactionDisplay[] }) => {
  const [data, setData] = useState(() => [...tableData]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // debugTable: true,
  });

  useEffect(() => {
    setData([...tableData]);
  }, [tableData]);

  return (
    <div className='group rounded-lg relative bg-light-dark'>
      <div className='px-0.5'>
        <div className='flex items-center justify-between p-4 bg-gray-800 border-2 m-5 border-gray-900/10 rounded-lg'>
          <div className='flex items-center w-full'>
            <Filter tableData={tableData} onFilter={setData} />
          </div>
        </div>
        {data.length ? (
          <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
            <table className='w-full border-collapse border-0 table-fixed'>
              <thead className='text-gray-400'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className='border-gray-700 border-b text-gray-400'>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className={clsx('font-normal px-6 py-3 border-gray-700 text-gray-400', {
                          'cursor-pointer': header.id == 'transaction',
                        })}
                        style={{ width: header.getSize() !== 150 ? header.getSize() : undefined }}
                        onClick={
                          header.id == 'transaction'
                            ? header.column.getToggleSortingHandler()
                            : undefined
                        }
                        // {header.id == 'transaction' ? ...{ onClick: header.column.getToggleSortingHandler() } : {}}
                      >
                        <div className='flex items-center'>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          <div className='ml-1'>
                            {{
                              asc: <ChevronUpIcon className='w-3 h-3' />,
                              desc: <ChevronDownIcon className='w-3 h-3' />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-white'>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className='items-center border-gray-700 border-b last:border-b-0'
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className='px-6 py-3 first:pl-6 last:pr-6'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <div className='mt-3 flex items-center justify-center rounded-lg px-5 py-4 text-sm shadow-card bg-light-dark lg:py-6'>
          <div className='flex items-center gap-5'>
            <Button
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
              title='Previous'
              shape='page'
              variant='page'
              color='page'
              size='square'
              className='text-white disabled:text-gray-400'
            >
              <ChevronDoubleLeftIcon className='h-auto w-4' />
            </Button>
            <Button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              title='Previous'
              shape='page'
              variant='page'
              color='page'
              size='square'
              className='text-white disabled:text-gray-400'
            >
              <ChevronLeftIcon className='h-auto w-4' />
            </Button>
            <div className='flex flex-col items-center justify-center'>
              <div>Page</div>
              <strong className='font-semibold'>
                {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
              </strong>
            </div>
            <Button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              title='Next'
              shape='page'
              variant='page'
              color='page'
              size='square'
              className='dark:text-white disabled:text-gray-400'
            >
              <ChevronRightIcon className='h-auto w-4' />
            </Button>
            <Button
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
              title='Next'
              shape='page'
              variant='page'
              color='page'
              size='square'
              className='dark:text-white disabled:text-gray-400'
            >
              <ChevronDoubleRightIcon className='h-auto w-4' />
            </Button>
          </div>
        </div> */}
            <TablePagination table={table} />
            {/* <div className='flex items-center gap-2'>
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className='border rounded p-1'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className='border rounded p-1'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className='flex items-center gap-1'>
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
          <span className='flex items-center gap-1'>
            | Go to page:
            <input
              type='number'
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className='border p-1 rounded w-16'
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div> */}
            {/* <div>{table.getRowModel().rows.length} Rows</div>
        <pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre> */}
          </div>
        ) : (
          <Empty showFiltersMessage />
        )}
      </div>
    </div>
  );
};

export default TransactionsTable1;
