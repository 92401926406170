import { PlusIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useConnectWallet } from '../../Hooks/useConnectWallet';
import { setModal } from '../../Redux/appReducer';
import { useAppSelector } from '../../Redux/index';
import {
  selectAllWallets,
  removeManualWallet,
  selectConnectedWallets,
} from '../../Redux/walletReducer';
import Button from '../../UI/button';
import AddWallet from './AddWallet';
import Wallet from './Wallet';

const Wallets = () => {
  const dispatch = useDispatch();
  const wallets = useAppSelector(selectAllWallets);
  const connected = useAppSelector(selectConnectedWallets);
  const { disconnect } = useConnectWallet();

  return (
    <div className='rounded-lg bg-light-dark overflow-hidden shadow min-h-[225px]'>
      <div className='bg-light-dark p-6'>
        <div className='sm:flex sm:items-center sm:justify-between'>
          <div className='sm:flex sm:space-x-5'>
            <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
              <p className='text-sm font-medium text-gray-400'>Connected Wallets</p>
              <p className='text-xl font-bold text-white sm:text-2xl'>{wallets.length}</p>
            </div>
          </div>
          <div className='mt-5 flex justify-center sm:mt-0'>
            <Button
              size='mini'
              onClick={() =>
                dispatch(
                  setModal({
                    title: 'Add Wallet',
                    content: () => <AddWallet wallets={wallets} />,
                  })
                )
              }
            >
              <div className='flex items-center gap-2'>
                <PlusIcon className='h-3 w-3' />
                Add New
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className='p-6'>
        <div className='space-y-8 md:space-y-10 xl:space-y-12'>
          <div className='grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4'>
            {wallets?.map((wallet, idx) => (
              <React.Fragment key={idx}>
                {wallet && (
                  <Wallet
                    wallet={wallet}
                    onDelete={() =>
                      connected.includes(wallet)
                        ? disconnect()
                        : dispatch(removeManualWallet(wallet.address))
                    }
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallets;
