import { useState, useEffect } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Button from '../../../../UI/button';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import { ParsedBalance } from '../../../../types';
import { Empty } from '../../../History/Empty';
import { formatUnits } from 'ethers/lib/utils';
import { BigNumber } from 'ethers';
// import { TablePagination } from './Pagination';
// import { Empty } from './Empty';

export const weiToInt = (wei) => parseInt(formatUnits(BigNumber.from(wei)));

export const formatShortNumber = (
  n: number | string | undefined,
  d: number,
  isWei?: boolean
): string => {
  if (!n || n === 0 || (typeof n === 'number' && isNaN(n))) return '0';
  const number: number = isWei ? weiToInt(typeof n === 'string' ? n : n) : Number(n);
  let abs = Math.abs(number);
  const rounder = Math.pow(10, d);
  const isNegative = number < 0;
  let key = '';

  const powers = [
    { key: 'Q', value: Math.pow(10, 15) },
    { key: 'T', value: Math.pow(10, 12) },
    { key: 'B', value: Math.pow(10, 9) },
    { key: 'M', value: Math.pow(10, 6) },
    { key: 'K', value: 1000 },
  ];

  for (let i = 0; i < powers.length; i++) {
    let reduced = abs / powers[i].value;
    reduced = Math.round(reduced * rounder) / rounder;
    if (reduced >= 1) {
      abs = reduced;
      key = powers[i].key;
      break;
    }
  }
  return `${isNegative ? '-' : ''}${abs}${key}`;
};

const columnHelper = createColumnHelper<ParsedBalance>();

const columns = [
  columnHelper.accessor('token', {
    id: 'token',
    cell: (info) => (
      <div className='flex flex-row items-center overflow-hidden'>
        <div className='relative flex items-center text-sm sm:text-base pointer-events-none select-none mr-1'>
          <img
            className='h-4 w-4 shrink-0 bg-white rounded-full border object-contain'
            src={info.getValue().icon}
          />
        </div>
        <p className='text-sm sm:text-base ml-0.5'>{info.getValue().symbol}</p>
      </div>
    ),
    header: () => <span>Token</span>,
    // minSize: 100,
    // maxSize: 140,
    size: 140,
  }),
  columnHelper.accessor('balance', {
    id: 'balance',
    cell: (info) => {
      const val = info.getValue();
      return (
        <div className='text-right'>
          {val > 1000000
            ? formatShortNumber(val, 2)
            : Number(val).toLocaleString(undefined, { maximumFractionDigits: val > 1 ? 2 : 8 })}
          {/* {} */}
        </div>
      );
    },
    header: () => <div className='ml-auto text-right'>Balance</div>,
    size: 360,
  }),
  columnHelper.accessor('prices', {
    id: 'prices',
    cell: (info) => {
      const val = info.getValue();
      return (
        <div className='text-right'>
          $
          {val?.USD.toLocaleString(undefined, {
            maximumFractionDigits: !!val && val.USD > 1 ? 4 : 8,
          })}
        </div>
      );
    },
    header: () => <div className='ml-auto text-right'>Price</div>,
    // minSize: 100,
    // maxSize: 140,
    size: 140,
  }),
  columnHelper.accessor('value', {
    id: 'value',
    cell: (info) => {
      const val = info.getValue();
      return (
        <div className='text-right'>
          ${val?.toLocaleString(undefined, { maximumFractionDigits: !!val && val > 1 ? 4 : 8 })}
        </div>
      );
    },
    header: () => <div className='ml-auto text-right'>Value</div>,
    // minSize: 100,
    // maxSize: 140,
    size: 140,
  }),
];

const TokensTable = ({ tableData, title }: { tableData: ParsedBalance[]; title: string }) => {
  const [data, setData] = useState(() => [...tableData]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // debugTable: true,
  });

  useEffect(() => {
    setData([...tableData]);
  }, [tableData]);

  return (
    <div className='group rounded-lg relative bg-light-dark'>
      <div className='px-0.5'>
        <div className='flex items-center justify-between -m-0.5 bg-gray-800 border-2 border-gray-900/10 rounded-t-lg'>
          <div className='flex items-center w-full p-4 px-6 font-medium'>{title}</div>
        </div>
        {data.length ? (
          <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
            <table className='w-full border-collapse border-0 table-fixed'>
              <thead className='text-gray-400'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className='border-gray-700 border-b text-gray-400'>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className='font-normal px-6 py-3 border-gray-700 text-gray-400'
                        style={{ width: header.getSize() !== 150 ? header.getSize() : undefined }}
                      >
                        <div className='flex items-center'>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-white'>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className='items-center border-gray-700 border-b last:border-b-0'
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className='px-6 py-3 first:pl-6 last:pr-6'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <TablePagination table={table} /> */}
          </div>
        ) : (
          <Empty showFiltersMessage />
        )}
      </div>
    </div>
  );
};

export default TokensTable;
