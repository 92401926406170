import { useState, useEffect } from 'react';
import { getWordpressContent } from '../../Api/blockpit';
import Loader from '../../UI/loader';

export const About = () => {
  const [html, setHtml] = useState({ __html: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const content = await getWordpressContent(50928);
        if (content?.content.rendered) setHtml({ __html: content.content.rendered });
      } catch (error) {
        setError(true);
      }
    })();
    setLoading(false);
  }, []);

  if (loading)
    return (
      <div className='w-full h-52'>
        <div className='flex items-center h-full justify-center'>
          <Loader />
        </div>
      </div>
    );
  if (error)
    return (
      <div className='w-full h-52'>
        <div className='flex items-center h-full justify-center'>
          There was an error loading the page
        </div>
      </div>
    );

  return html ? (
    <div className='external w-full' dangerouslySetInnerHTML={html} />
  ) : (
    <div className='w-full'>No content</div>
  );
};
