import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '.';
import { StoredWallet } from '../types';
import { Report, ReportResponse } from '../Api/blockpit';

type WalletState = {
  connectedWallets: StoredWallet[];
  manualWallets: StoredWallet[];
  singleWallet: StoredWallet | null;
  chain: number;
  reports: Report[] | null;
};

const initialState: WalletState = {
  connectedWallets: [],
  manualWallets: [],
  singleWallet: null,
  chain: -1,
  reports: null,
};

export const walletReducer = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setConnectedWallets: (state: WalletState, { payload: wallet }: PayloadAction<StoredWallet>) => {
      state.connectedWallets = [wallet];
    },
    setManualWallets: (state: WalletState, { payload: wallets }: PayloadAction<StoredWallet[]>) => {
      state.manualWallets = wallets;
    },
    setSingleWallet: (state: WalletState, { payload: wallet }: PayloadAction<StoredWallet>) => {
      state.singleWallet = wallet;
    },
    addManualWallet: (state: WalletState, { payload: wallet }: PayloadAction<StoredWallet>) => {
      state.manualWallets = [...state.manualWallets, wallet];
    },
    removeManualWallet: (state: WalletState, { payload: wallet }: PayloadAction<string>) => {
      state.manualWallets = [...state.manualWallets.filter((w) => w.address !== wallet)];
    },
    removeConnectedWallets: (state: WalletState, { payload: all }: PayloadAction<boolean>) => {
      state.connectedWallets = [];
    },
    removeSingleWallet: (state: WalletState) => {
      state.singleWallet = null;
    },
    setChain: (state: WalletState, { payload: chain }: PayloadAction<number>) => {
      state.chain = chain;
    },
    addReport: (state: WalletState, { payload: report }: PayloadAction<Report>) => {
      state.reports = [...(state.reports || []), report];
    },
    removeReport: (state: WalletState, { payload: report }: PayloadAction<string>) => {
      state.reports = [...(state.reports || []).filter((r) => r.requestId !== report)];
    },
    updateReport: (state: WalletState, { payload: report }: PayloadAction<Report>) => {
      state.reports = [
        ...(state.reports || []).map((r) => (r.requestId === report.requestId ? report : r)),
      ];
    },
  },
});

export const {
  setConnectedWallets,
  setManualWallets,
  setSingleWallet,
  addManualWallet,
  removeSingleWallet,
  removeManualWallet,
  removeConnectedWallets,
  setChain,
  addReport,
  removeReport,
  updateReport,
} = walletReducer.actions;

export const selectChain = (state: RootState) => state.wallet.chain;
export const selectConnectedWallets = (state: RootState) => state.wallet.connectedWallets;
export const selectManualWallets = (state: RootState) => state.wallet.manualWallets;
export const selectSingleWallet = (state: RootState) => state.wallet.singleWallet;
export const selectReports = (state: RootState) => state.wallet.reports;
export const selectAllWallets = (state: RootState) => [
  ...state.wallet.connectedWallets,
  ...state.wallet.manualWallets,
];

export default walletReducer.reducer;
