/* eslint-disable tailwindcss/no-custom-classname */
import { useContext, useState } from 'react';
import { useConnectWallet } from '../Hooks/useConnectWallet';
import Button from '../UI/button';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import Connect from './Connect';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useToast } from '../Hooks/useToast';
import { config } from '../config';
import { chainIds } from '../Api/blockpit';
import { validateAddress } from '../Utils/addressValidator';
import {
  setSingleWallet,
  selectSingleWallet,
  removeSingleWallet,
  selectAllWallets,
} from '../Redux/walletReducer';
import { useAppSelector } from '../Redux/index';
import { addManualWallet } from '../Redux/walletReducer';
import { setModal } from '../Redux/appReducer';
import { useDrawer } from '../Hooks/useDrawer';
import Hamburger from './Hamburger';
import { isEqual } from 'lodash';
export const routes = [
  {
    label: 'Dashboard',
    path: 'dashboard',
  },
  {
    label: 'History',
    path: 'history',
  },
  {
    label: 'NFT Gallery',
    path: 'nft',
  },
  {
    label: 'Wallets',
    path: 'wallets',
  },
  {
    label: 'Tax Report',
    path: 'report',
  },
  {
    label: 'Developers',
    path: 'developers',
    hide: true,
  },
  {
    label: 'About',
    path: 'about',
    hide: true,
  },
];
const Header = () => {
  // const { connect, disconnect } = useConnectWallet();
  // const { address, isConnected } = useAccount();
  // const { connect } = useConnect({
  //   connector: new InjectedConnector(),
  // });
  // const { disconnect } = useDisconnect();
  const singleWallet = useAppSelector(selectSingleWallet);
  const [newWallet, setNewWallet] = useState<string>('');
  const [disabledInput, setDisabledInput] = useState<boolean>(!!singleWallet);
  const dispatch = useDispatch();
  const toast = useToast();
  const wallets = useAppSelector(selectAllWallets, isEqual);
  const drawer = useDrawer();
  const showDrawerMenu = () => drawer.show({ content: () => <></> });

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // TODO sanitize
    setNewWallet(e.target.value);
  };

  // const onAddWallet = async () => {
  //   if (config.validateLocally) {
  //     // validateWall(newWallet);
  //     const validChains = chainIds
  //       .map((chain) => ({ chain, valid: validateAddress(newWallet, chain) }))
  //       .filter((c) => c.valid)
  //       .map((c) => c.chain);
  //     dispatch(setSingleWallet({ address: newWallet, validFor: validChains }));
  //     setNewWallet('');
  //     setDisabledInput(true);
  //     toast.success('Success', 'Wallet added');
  //   } else {
  //   }
  // };

  const onAddWallet = async () => {
    if (newWallet.length > 29) {
      // const checklocal = validate(newWallet, 'cosmos', {
      //   networkType: 'prod',
      //   chainType: 'mainnet',
      // });
      // if (newWallet)
      if (wallets.find((w) => w.address.toLowerCase() === newWallet.toLowerCase())) {
        // console.log('it exists');
        toast.error('Not Added', 'The address provided already exists in your wallet list');
        dispatch(setModal(null));
        return;
      }
      if (config.validateLocally) {
        // validateWall(newWallet);
        const validChains = chainIds
          .map((chain) => ({ chain, valid: validateAddress(newWallet, chain) }))
          .filter((c) => c.valid)
          .map((c) => c.chain);
        // console.log('validChains :>> ', validChains);
        dispatch(addManualWallet({ address: newWallet, validFor: validChains }));
        setNewWallet('');
        toast.success('Success', 'Wallet added');
        dispatch(setModal(null));
      } else {
        // const checklocal2 = !!validate(newWallet, 'Osmosis', {
        //   networkType: 'prod',
        //   chainType: 'mainnet',
        // });
        // const checklocal2 = validateAddress(newWallet, 'Osmosis');
        // console.log('checklocal2', checklocal2);
        // TODO check wallet not existing
        // const isValid = await validateWalletAll(newWallet);
        // setLoading(true);
        // const validations = await chains.reduce(async (promise, chain) => {
        //   const isValid = await validateWallet(newWallet, chain.id);
        //   if (isValid?.result === 'true') setValidChains((v) => [...v, chain]);
        // }, (Promise.resolve(), console.log('dojne')));
        // Promise.allSettled(
        //   chainIds.map((chain) =>
        //     blockpitApi
        //       .get<Response>(`/${chain}/IsValidImportAccount?address=${newWallet}`, {})
        //       .then((r) => ({ chain, valid: r?.ok && r?.data?.result === 'true' }))
        //       .catch(() => ({ chain, valid: false }))
        //   )
        // ).then(() => console.log('now done'));
        // setLoading(false);
        // dispatch(addManualWallet(newWallet));
        // dispatch(setModal(null));
      }
    } else {
      toast.error('Invalid Wallet Address', 'Please verify the address provided');
    }
  };

  return (
    <header className='pb-24'>
      <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-5xl lg:px-8'>
        <div className='relative flex flex-wrap items-center justify-center lg:justify-between h-20 lg:h-auto'>
          <div className='absolute left-0 py-5 shrink-0 lg:static'>
            <a href='#'>
              <img className='w-36' src='/images/BananaBoardLogo.svg' alt='logo' />
            </a>
          </div>
          <div className='flex items-center lg:hidden ml-auto h-full'>
            <Hamburger
              isOpen={false}
              onClick={showDrawerMenu}
              color='primary'
              className='shadow-main border border-solid border-gray-700 bg-light-dark text-white'
            />
          </div>

          <div className='hidden lg:ml-4 lg:flex lg:items-center lg:py-5 lg:pr-0.5'>
            <div className='flex items-center space-x-2 text-sm font-bold'>
              {/* <a href='/developers' className='text-white/80 transition hover:text-white'>
                Developers
              </a> */}
              <NavLink to={'/developers'} className='text-white/80 transition hover:text-white'>
                Developers
              </NavLink>
              <NavLink to={'/about'} className='text-white/80 transition hover:text-white'>
                About
              </NavLink>
              {/* <a href='' className='text-white/80 transition hover:text-white'>
                Developers
              </a> */}
            </div>
            {singleWallet && (
              <div className='ml-4 relative shrink-0'>
                <Button
                  size='mini'
                  onClick={() =>
                    dispatch(
                      setModal({
                        title: '',
                        content: () => (
                          <div className='flex items-center p-4 justify-between'>
                            Remove tracked wallet?
                            <Button
                              size='mini'
                              onClick={() => dispatch(removeSingleWallet())}
                              color='info'
                            >
                              Single Wallet Mode
                            </Button>
                          </div>
                        ),
                      })
                    )
                  }
                  color='info'
                >
                  Single Wallet Mode
                </Button>
              </div>
            )}
            <div className='ml-4 relative shrink-0'>
              <div>
                <Connect />
              </div>
            </div>
          </div>

          <div className='w-full py-5 lg:border-t lg:border-white lg:border-opacity-20'>
            <div className='lg:grid lg:grid-cols-3 lg:gap-8 lg:items-center'>
              <div className='hidden lg:block lg:col-span-2'>
                <nav className='flex space-x-4'>
                  {routes
                    .filter((r) => !r?.hide)
                    .map((route, index) => (
                      <NavLink
                        key={index}
                        to={route.path}
                        className='nav-link text-white text-sm font-medium rounded-md px-3 py-2 hover:bg-white/10'
                      >
                        {route.label}
                      </NavLink>
                    ))}
                </nav>
              </div>
              <div className='px-12 lg:px-0 hidden lg:block'>
                <div className='max-w-xs mx-auto w-full lg:max-w-md'>
                  <label className='sr-only'>Track a wallet</label>
                  <div className='relative text-white focus-within:text-gray-600'>
                    <div className='pointer-events-none absolute inset-y-0 left-0 pl-3 items-center hidden sm:flex'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-5 h-5'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3'
                        />
                      </svg>
                    </div>
                    {/* <input
                      id='search'
                      className='block w-full text-white bg-white/20 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 focus:text-gray-900 placeholder:text-white focus:outline-none focus:bg-white focus:border-transparent focus:placeholder:text-gray-500 focus:ring-0 sm:text-sm'
                      placeholder='Track a wallet'
                      type='search'
                      name='search'
                    /> */}
                    <input
                      disabled={disabledInput}
                      pattern='[0-9]*'
                      autoComplete='off'
                      placeholder='Track a wallet'
                      value={newWallet}
                      onChange={onAddressChange}
                      onKeyPress={(e) => e.key === 'Enter' && onAddWallet()}
                      className={clsx(
                        'block w-full text-white bg-white/20 py-2 pl-5 sm:pl-10 pr-3 border border-transparent rounded-md leading-5 focus:text-gray-900 placeholder:text-white focus:outline-none focus:bg-white focus:border-transparent focus:placeholder:text-gray-500 focus:ring-0 text-xs sm:text-sm',
                        {
                          'opacity-50': disabledInput,
                        }
                      )}
                      type='text'
                      name='fromAmount'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='absolute right-0 flex-shrink-0 lg:hidden'>
            <button
              type='button'
              className='bg-transparent p-2 rounded-md inline-flex items-center justify-center text-cyan-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white'
              aria-expanded='false'
            >
              <span className='sr-only'>Open main menu</span>
              <svg
                className='block h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M4 6h16M4 12h16M4 18h16'
                />
              </svg>
              <svg
                className='hidden h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div> */}
        </div>
      </div>

      {/* MOBILE MENU */}
      {/* <div className='lg:hidden'>
        <div className='z-20 fixed inset-0 bg-black bg-opacity-25' aria-hidden='true'></div>
        <div className='z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition origin-top'>
          <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200'>
            <div className='pt-3 pb-2'>
              <div className='flex items-center justify-between px-4'>
                <div>
                  <img
                    className='h-8 w-auto'
                    src='https://tailwindui.com/img/logos/workflow-mark-cyan-600.svg'
                    alt='Workflow'
                  />
                </div>
                <div className='-mr-2'>
                  <button
                    type='button'
                    className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500'
                  >
                    <span className='sr-only'>Close menu</span>
                    <svg
                      className='h-6 w-6'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className='mt-3 px-2 space-y-1'>
                <a
                  href='#'
                  className='block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                >
                  Home
                </a>

                <a
                  href='#'
                  className='block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                >
                  Profile
                </a>

                <a
                  href='#'
                  className='block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                >
                  Resources
                </a>

                <a
                  href='#'
                  className='block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                >
                  Company Directory
                </a>

                <a
                  href='#'
                  className='block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                >
                  Openings
                </a>
              </div>
            </div>
            <div className='pt-4 pb-2'>
              <div className='flex items-center px-5'>
                <div className='shrink-0'>
                  <img
                    className='h-10 w-10 rounded-full'
                    src='https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                    alt=''
                  />
                </div>
                <div className='ml-3 min-w-0 flex-1'>
                  <div className='text-base font-medium text-gray-800 truncate'>Chelsea Hagon</div>
                  <div className='text-sm font-medium text-gray-500 truncate'>
                    chelsea.hagon@example.com
                  </div>
                </div>
                <button
                  type='button'
                  className='ml-auto shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500'
                >
                  <span className='sr-only'>View notifications</span>
                  <svg
                    className='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
                    />
                  </svg>
                </button>
              </div>
              <div className='mt-3 px-2 space-y-1'>
                <a
                  href='#'
                  className='block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                >
                  Your Profile
                </a>

                <a
                  href='#'
                  className='block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                >
                  Settings
                </a>

                <a
                  href='#'
                  className='block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                >
                  Sign out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </header>
  );
};

export default Header;
