import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Chain, chainIds } from '../../Api/blockpit';
import { validateAddress } from '../../Utils/addressValidator';
import Button from '../../UI/button';
import Loader from '../../UI/loader';
import { config } from '../../config';
import { addManualWallet } from '../../Redux/walletReducer';
import { dismissModal, setModal } from '../../Redux/appReducer';
import { useToast } from '../../Hooks/useToast';
import { StoredWallet } from '../../types';

const AddWallet = ({ wallets }: { wallets: StoredWallet[] }) => {
  const [newWallet, setNewWallet] = useState<string>('');
  const [validChains, setValidChains] = useState<Chain[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const toast = useToast();

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // TODO sanitize
    setNewWallet(e.target.value);
  };

  const onAddWallet = async () => {
    // const checklocal = validate(newWallet, 'cosmos', {
    //   networkType: 'prod',
    //   chainType: 'mainnet',
    // });
    // if (newWallet)
    if (wallets.find((w) => w.address.toLowerCase() === newWallet.toLowerCase())) {
      // console.log('it exists');
      toast.error('Not Added', 'The address provided already exists in your wallet list');
      dispatch(setModal(null));
      return;
    }
    if (config.validateLocally) {
      // validateWall(newWallet);
      const validChains = chainIds
        .map((chain) => ({ chain, valid: validateAddress(newWallet, chain) }))
        .filter((c) => c.valid)
        .map((c) => c.chain);
      // console.log('validChains :>> ', validChains);
      dispatch(addManualWallet({ address: newWallet, validFor: validChains }));
      toast.success('Success', 'Wallet added');
      dispatch(setModal(null));
    } else {
      // const checklocal2 = !!validate(newWallet, 'Osmosis', {
      //   networkType: 'prod',
      //   chainType: 'mainnet',
      // });
      // const checklocal2 = validateAddress(newWallet, 'Osmosis');
      // console.log('checklocal2', checklocal2);
      // TODO check wallet not existing
      // const isValid = await validateWalletAll(newWallet);
      // setLoading(true);
      // const validations = await chains.reduce(async (promise, chain) => {
      //   const isValid = await validateWallet(newWallet, chain.id);
      //   if (isValid?.result === 'true') setValidChains((v) => [...v, chain]);
      // }, (Promise.resolve(), console.log('dojne')));
      // Promise.allSettled(
      //   chainIds.map((chain) =>
      //     blockpitApi
      //       .get<Response>(`/${chain}/IsValidImportAccount?address=${newWallet}`, {})
      //       .then((r) => ({ chain, valid: r?.ok && r?.data?.result === 'true' }))
      //       .catch(() => ({ chain, valid: false }))
      //   )
      // ).then(() => console.log('now done'));
      // setLoading(false);
      // dispatch(addManualWallet(newWallet));
      // dispatch(setModal(null));
    }
  };
  return (
    <>
      <div className='text-sm border-b border-gray-700'>
        <div className='mx-auto w-full rounded-lg p-5 pt-4 shadow-card bg-light-dark xs:p-6 xs:pl-4 xs:pt-5'>
          <div className='flex flex-1 flex-col text-right'>
            <input
              pattern='[0-9]*'
              autoComplete='off'
              placeholder='Wallet Address'
              value={newWallet}
              onChange={onAddressChange}
              className='w-full rounded-r-lg border-0 pb-0.5 text-left text-lg outline-none focus:ring-0 bg-light-dark'
              type='text'
              name='fromAmount'
            />
          </div>
        </div>
      </div>
      <div className='px-6 py-4 flex justify-between'>
        <div className='flex justify-start w-3/6 items-center gap-2'>
          {validChains.map((chain) => (
            <>
              <img
                key={chain.id}
                src={`/images/tokens/${chain.icon}`}
                alt={chain.name}
                className='h-6 w-6 shrink-0 bg-white rounded-full border object-contain'
              />
            </>
          ))}
        </div>
        {loading && (
          <Loader
            size='small'
            showOnlyThreeDots
            className='ml-auto mr-4 scale-75'
            variant='scaleUp'
          />
        )}
        <Button size='small' onClick={onAddWallet} disabled={newWallet.length < 30 || loading}>
          Add
        </Button>
      </div>
    </>
  );
};

export default AddWallet;
