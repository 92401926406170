import { useConnectWallet } from '../Hooks/useConnectWallet';
import Button from '../UI/button';

const ConnectButton = () => {
  const { connect, disconnect, isConnected, address } = useConnectWallet();

  if (isConnected)
    return (
      <Button size='mini' onClick={() => disconnect()}>
        Disconnect
      </Button>
    );
  return (
    <Button size='mini' onClick={() => connect()}>
      Connect Wallet
    </Button>
  );
};

export default ConnectButton;
