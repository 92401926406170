import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import walletReducer from './walletReducer';
import appReducer from './appReducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['app'],
};

const reducers = combineReducers({ wallet: walletReducer, app: appReducer });
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// import fullGameReducer from './fullGame';
// import authReducer from './authReducer';
// import { apiSlice } from './apiReducer';

// export const store = configureStore({
//   reducer: {
//     fullGame: fullGameReducer,
//     auth: authReducer,
//     [apiSlice.reducerPath]: apiSlice.reducer,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }).concat(apiSlice.middleware),
// });

setupListeners(store.dispatch);

export type Store = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
