import { useState, useEffect } from 'react';
import { walletCollections, walletNfts } from '../../Api/nft';
import { useAppSelector } from '../../Redux/index';
import { selectAllWallets } from '../../Redux/walletReducer';
import { NFTCollectionProps, StoredWallet } from '../../types';
import { NFTAssetResponse } from '../../types/index';
import NFTCollection from './Collection';
import NFTAsset from './Asset';
import SelectWallet from './SelectWallet';
import Loader from '../../UI/loader';
import Skeleton from '../../UI/skeleton/no-wallet';
import { getWalletNfts, getWalletNftsByCollection, NFTSingleAsset } from '../../Api/blockpit';

const NFTGallery = () => {
  const wallets = useAppSelector(selectAllWallets);
  const [loading, setLoading] = useState(true);
  const [collections, setCollections] = useState<NFTCollectionProps[]>([]);
  const [activeCollection, setActiveCollection] = useState<NFTCollectionProps>();
  const [activeCollectionAssets, setActiveCollectionAssets] = useState<NFTSingleAsset[]>([]);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [activeWallet, setActiveWallet] = useState<StoredWallet>(
    wallets.find((w) => w.validFor.includes('Ethereum')) || wallets[0]
  );

  useEffect(() => {
    setCollections([]);
    setActiveCollection(undefined);
    setLoading(true);
    (async () => {
      if (activeWallet) {
        const some = await getWalletNfts(activeWallet.address, 'Ethereum');
        console.log('some :>> ', some);
        // const res = await walletCollections(activeWallet.address);
        // if (res?.length) {
        //   const collections = res.map((nft) => {
        //     const c = nft.collection;
        //     return {
        //       name: c.name,
        //       contract: c.primaryContract,
        //       coverImg: c.banner || c.image,
        //       slug: c.slug,
        //       owned: nft.ownership.tokenCount,
        //     };
        //   });
        //   setCollections(collections);
        // }
        if (some && some?.result?.length) {
          const collections = some.result.map((nft) => {
            const c = nft;
            return {
              name: c.name,
              contract: c.address,
              coverImg: c.sample_image,
              slug: '0',
              owned: '1',
            };
          });
          setCollections(collections);
        }
      }
      setLoading(false);
    })();
  }, [activeWallet]);

  useEffect(() => {
    (async () => {
      if (activeWallet && activeCollection?.contract) {
        setLoadingAssets(true);
        // const res = await walletNfts(activeWallet.address, activeCollection.contract);
        const res2 = await getWalletNftsByCollection(
          activeWallet.address,
          'Ethereum',
          activeCollection.contract
        );
        if (res2) {
          setActiveCollectionAssets(res2?.result || []);
          setLoadingAssets(false);
        }
      }
    })();
  }, [activeCollection]);

  if (!wallets.length || !activeWallet) return <Skeleton message='Connect a wallet first' />;

  if (!activeWallet.validFor.includes('Ethereum'))
    return <Skeleton message='NFT is currently available for Ethereum wallets only' />;

  return (
    <>
      <div className='rounded-lg bg-light-dark overflow-hidden shadow min-h-[250px]'>
        <div className='bg-light-dark p-6'>
          <div className='sm:flex sm:items-center sm:justify-between'>
            <div className='sm:flex sm:space-x-5'>
              <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
                <p className='text-sm font-medium text-gray-400'>NFT Collections</p>
                <p className='text-xl font-bold text-white sm:text-2xl'>
                  {loading ? (
                    <Loader variant='blink' size='small' className='mt-4 ml-0.5' />
                  ) : (
                    collections.length
                  )}
                </p>
              </div>
            </div>
            {wallets.length > 0 && (
              <div className='mt-5 flex justify-center sm:mt-0'>
                <SelectWallet
                  wallets={wallets}
                  selectedWallet={activeWallet}
                  setSelectedWallet={setActiveWallet}
                />
              </div>
            )}
          </div>
        </div>
        <div className='p-6'>
          {loading ? (
            <div className='flex w-full justify-center items-center'>
              <Loader showOnlyThreeDots variant='blink' />
            </div>
          ) : (
            <>
              <div className='flex flex-col items-center justify-center w-full h-full'>
                <div className='grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                  {collections &&
                    collections.map((nft) => (
                      <NFTCollection collection={nft} onClick={() => setActiveCollection(nft)} />
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {activeCollection && (
        <div className='rounded-lg bg-light-dark overflow-hidden shadow'>
          <div className='bg-light-dark p-6'>
            <div className='sm:flex sm:items-center sm:justify-between'>
              <div className='sm:flex sm:space-x-5'>
                <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
                  <p className='text-sm font-medium text-gray-400'>
                    <>
                      {loadingAssets ? (
                        <>Loading...</>
                      ) : (
                        <>
                          Assets Owned in <b>{activeCollection?.name}</b>
                        </>
                      )}
                    </>
                  </p>
                  <p className='text-xl font-bold text-white sm:text-2xl'>
                    {!loadingAssets && activeCollectionAssets.length}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='p-6'>
            {loadingAssets ? (
              <div className='flex w-full justify-center items-center'>
                <Loader showOnlyThreeDots variant='blink' />
              </div>
            ) : (
              <div className='flex flex-col items-center justify-center w-full h-full'>
                <div className='grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-full'>
                  {activeCollectionAssets &&
                    activeCollectionAssets.map((asset) => (
                      <NFTAsset asset={asset} contract={activeCollection.contract} />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default NFTGallery;
