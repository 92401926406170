import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { updateDrawer, dismissDrawer, setDrawer } from '../Redux/appReducer';
import { Drawer } from '../UI/drawer';

export const useDrawer = (update?: Partial<Drawer>) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (update) {
      dispatch(updateDrawer(update));
    }
  }, []);

  return {
    hide: () => dispatch(dismissDrawer()),
    show: (drawer: Drawer) => dispatch(setDrawer(drawer)),
    showBack: (onBack: () => void) => dispatch(updateDrawer({ onBack })),
    hideBack: () => dispatch(updateDrawer({ onBack: undefined })),
    update: (drawer: Partial<Drawer>) => dispatch(updateDrawer(drawer)),
  };
};
