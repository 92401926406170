import { useDispatch } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { SerializedError } from '@reduxjs/toolkit';
import { addToastMessage } from '../Redux/appReducer';

export const useToast = () => {
  const dispatch = useDispatch();
  return {
    success: (title: string, message: string) => {
      dispatch(
        addToastMessage({
          title,
          message,
          type: 'success',
        })
      );
    },
    error: (title: string, message: string) => {
      dispatch(
        addToastMessage({
          title,
          message,
          type: 'error',
        })
      );
    },
  };
};
